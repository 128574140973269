import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setKeywords, setLis } from "./actions";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";
import kor_eng from "../data/select_kor_eng.json";

const MbtiStudy = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translations, language } = useContext(LanguageContext);
  const [animation, setAnimation] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const handleGoMBTI = () => {
    sessionStorage.setItem("wherembti", "study");
    navigate("/mbtisetting");
  };

  const handleNextClick2 = () => {
    if (selectedGenders.length === 0) {
      const toastId = "tag-error";
      setAnimation("animate-shake border-red-500"); // Trigger shake animation for error
      if (!toast.isActive(toastId)) {
        // Show error toast if email is invalid
        toast.error("태그를 하나 이상 선택해주세요", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
        });
      }
      return null;
    }

    const tagIds = selectedGenders.join(",");
    const fetchData = async () => {
      try {
        const response = await fetch(
          // `${apiUrl}/customize/managerFilter?tagid=${tagIds}&limit=1000&page=1`
          `${apiUrl}/customize/managerFilter?mbti=${userInfo.mbti}&tagid=${tagIds}&limit=1000&page=1`
        );
        const fetchedLis = await response.json();
        if (response.ok) {
          setLis(fetchedLis);
          // Store fetched data in state
          const filteredList = fetchedLis.data
            .filter((item) => item.mbti !== "")
            .filter((item) => item.used === true);

          dispatch(setLis(filteredList));
          console.log(filteredList);
          dispatch(setKeywords(selectedKeys));
          navigate("/mbtistudylist");
        }
      } catch (error) {}
    };

    fetchData();
  };

  const handleGoBack = () => {
    navigate("/study");
  };

  // Use an array to keep track of multiple selected items
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedIndex2, setExpandedIndex2] = useState(null);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [data, setData] = useState({});

  const [topics, setTopics] = useState([]);

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Close the currently expanded topic
    } else {
      setExpandedIndex(index); // Open the clicked topic
      setExpandedIndex2(index); // Open the clicked topic
    }
  };

  const handleSelect = (value) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(value)) {
        // If the value is already selected, remove it
        return prevSelectedGenders.filter((item) => item !== value);
      } else {
        // Otherwise, add the value
        return [...prevSelectedGenders, value];
      }
    });
  };

  // const handleSelect2 = (value) => {
  //   setSelectedKeys((prevSelectedKeys) => {
  //     if (prevSelectedKeys.includes(value)) {
  //       return prevSelectedKeys;
  //     } else {
  //       // Otherwise, add the value
  //       return [...prevSelectedKeys, value];
  //     }
  //   });
  // };

  const handleSelect2 = (value) => {
    setSelectedKeys((prevSelectedKeys) => {
      // Initialize the new array based on the previous state
      const newSelectedKeys = [...prevSelectedKeys];

      // Determine the opposite language
      const oppositeLanguage = language === "kor" ? "eng" : "kor";

      // First, find the secondLevelChild that contains the title matching the current language
      const secondLevelChild = data.children
        .flatMap((firstLevelChild) => firstLevelChild.children)
        .find((child) =>
          child.translations.some(
            (translation) =>
              translation.language === language.toLowerCase() &&
              translation.name === value
          )
        );

      if (secondLevelChild) {
        // Find the title in the current language
        const currentLanguageTitle = secondLevelChild.translations.find(
          (translation) => translation.language === language.toLowerCase()
        )?.name;

        // Find the title in the opposite language
        const oppositeLanguageTitle = secondLevelChild.translations.find(
          (translation) =>
            translation.language === oppositeLanguage.toLowerCase()
        )?.name;

        // Add the titles to the newSelectedKeys array if they are not already present
        if (
          currentLanguageTitle &&
          oppositeLanguageTitle &&
          newSelectedKeys.includes(currentLanguageTitle) &&
          newSelectedKeys.includes(oppositeLanguageTitle)
        ) {
          // Remove both titles
          return newSelectedKeys.filter(
            (key) =>
              key !== currentLanguageTitle && key !== oppositeLanguageTitle
          );
        } else {
          // Add the titles if they are not already present
          if (
            currentLanguageTitle &&
            !newSelectedKeys.includes(currentLanguageTitle)
          ) {
            newSelectedKeys.push(currentLanguageTitle);
          }
          if (
            oppositeLanguageTitle &&
            !newSelectedKeys.includes(oppositeLanguageTitle)
          ) {
            newSelectedKeys.push(oppositeLanguageTitle);
          }
        }
      }

      return newSelectedKeys;
    });
  };

  useEffect(() => {}, [selectedGenders]);
  useEffect(() => {
    setSelectedGenders([]);
  }, [expandedIndex2]);

  // Fetch data once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, []); // Empty dependency array: fetch data only once on mount

  useEffect(() => {
    if (data && data.children) {
      const topics = data.children.flatMap(
        (firstLevelChild, firstLevelIndex) => {
          const firstLevelNames = firstLevelChild.translations
            ? firstLevelChild.translations
                .filter(
                  (translation) =>
                    translation.language === language.toLowerCase()
                )
                .map((translation) => translation.name)
            : [];

          const secondLevel = firstLevelChild.children || [];
          const secondLevelData = secondLevel.flatMap(
            (secondLevelChild, secondLevelIndex) => {
              const secondLevelNames = secondLevelChild.translations
                ? secondLevelChild.translations
                    .filter(
                      (translation) =>
                        translation.language === language.toLowerCase()
                    )
                    .map((translation) => translation.name)
                : [];

              const reversedSecondLevelNames = [...secondLevelNames].reverse();

              const thirdLevel = secondLevelChild.children || [];
              const thirdLevelData = thirdLevel.flatMap((thirdLevelChild) =>
                thirdLevelChild.translations
                  ? thirdLevelChild.translations
                      .filter(
                        (translation) =>
                          translation.language === language.toLowerCase()
                      )
                      .map((translation) => ({
                        name: translation.name,
                        id: thirdLevelChild.id,
                      }))
                  : []
              );

              const reversedThirdLevelNames = [...thirdLevelData].reverse();

              return reversedSecondLevelNames.map((name, index) => ({
                id: `second-${firstLevelIndex}-${secondLevelIndex}-${index}`,
                title: name,
                thirdLevelData: reversedThirdLevelNames, // Include third-level data
              }));
            }
          );

          const reversedSecondLevelData = [...secondLevelData].reverse();

          return firstLevelNames.map((firstLevelName, index) => ({
            id: `first-${firstLevelIndex}-${index}`,
            title: firstLevelName,
            content: (
              <div key={`first-content-${index}`}>
                <div className="flex flex-wrap gap-2">
                  {reversedSecondLevelData.map((secondLevelTopic) => {
                    // Check if all thirdLevelData items are selected
                    const allSelected = secondLevelTopic.thirdLevelData.every(
                      (thirdName) => selectedGenders.includes(thirdName.id)
                    );
                    return (
                      <div key={secondLevelTopic.id}>
                        <div className="font-bold text-[#000]  dark:text-[#fff] mb-2">
                          {secondLevelTopic.title}
                        </div>
                        <div className="flex flex-wrap gap-2">
                          <div
                            className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                              allSelected
                                ? darkMode
                                  ? "bg-[#fff] border-[#fff] text-[#000]"
                                  : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                                : "border-[#e9ecef] text-[#000]"
                            }`}
                            style={{ flexBasis: "calc(33.333% - 6px)" }}
                            onClick={() => {
                              if (allSelected) {
                                // If all are selected, remove them
                                const idsToRemove =
                                  secondLevelTopic.thirdLevelData.map(
                                    (thirdName) => thirdName.id
                                  );
                                const keysToRemove =
                                  secondLevelTopic.thirdLevelData.map(
                                    (thirdName) => thirdName.name
                                  );
                                setSelectedGenders((prevSelectedGenders) =>
                                  prevSelectedGenders.filter(
                                    (id) => !idsToRemove.includes(id)
                                  )
                                );
                                setSelectedKeys((prevSelectedKeys) =>
                                  prevSelectedKeys.filter(
                                    (key) => !keysToRemove.includes(key)
                                  )
                                );
                              } else {
                                // If not all are selected, add only those not already selected
                                const idsToAdd = secondLevelTopic.thirdLevelData
                                  .map((thirdName) => thirdName.id)
                                  .filter(
                                    (id) => !selectedGenders.includes(id)
                                  );
                                const keysToAdd =
                                  secondLevelTopic.thirdLevelData
                                    .map((thirdName) => thirdName.name)
                                    .filter(
                                      (name) => !selectedKeys.includes(name)
                                    );
                                setSelectedGenders((prevSelectedGenders) => [
                                  ...prevSelectedGenders,
                                  ...idsToAdd,
                                ]);
                                setSelectedKeys((prevSelectedKeys) => [
                                  ...prevSelectedKeys,
                                  ...keysToAdd,
                                ]);
                              }
                            }}
                          >
                            <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                              {translations.ALL}
                            </div>
                          </div>
                          {secondLevelTopic.thirdLevelData.map((thirdName) => (
                            <div
                              key={thirdName.id}
                              onClick={() => {
                                handleSelect(thirdName.id);
                                handleSelect2(secondLevelTopic.title);
                              }}
                              className={`flex items-center justify-center py-[8px] px-[14px]  border-[1px] border-solid rounded-[59px] ${
                                selectedGenders.includes(thirdName.id)
                                  ? darkMode
                                    ? "bg-[#fff] border-[#fff] text-[#000]"
                                    : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                                  : darkMode
                                  ? " border-[#e9ecef] text-[#fff]"
                                  : " border-[#e9ecef] text-[#000]"
                              }`}
                              style={{ flexBasis: "calc(33.333% - 6px)" }}
                            >
                              <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                                {thirdName.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ),
          }));
        }
      );

      setTopics(topics.reverse());
    }
  }, [data, selectedGenders, language]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            {/* <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
              /> */}
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={30}
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] text-center">
            MBTI
          </h1>
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </header>

        <main className="flex flex-col items-center gap-4 px-4 mt-6">
          <div className="flex items-start justify-between w-full p-4 bg-[#fff] dark:bg-[#22232a] rounded-[12px] shadow">
            <div className="flex items-center gap-4">
              <div className="relative flex flex-col items-center flex-shrink-0">
                <img width="70" height="78" src="avatar.png" alt="ENTP" />
                <span className="absolute bottom-2 text-[16px] font-sans font-bold text-[#fff]">
                  {userInfo.mbti}
                </span>
              </div>
              <div className="flex flex-col items-start gap-2">
                <span className="self-center text-[14px] font-sans font-bold text-[#000] dark:text-[#fff]">
                  {translations[userInfo.mbti + "1"]}
                </span>
                <div className="flex items-start gap-2">
                  <img
                    width="12"
                    height="11"
                    src="opening.png"
                    alt="Quote start"
                  />
                  <span className="text-[14px] font-sans text-[#666b81] word-break break-word">
                    {translations[userInfo.mbti + "2"]}
                  </span>
                  <img
                    width="12"
                    height="11"
                    src="closing.png"
                    alt="Quote end"
                  />
                </div>
              </div>
            </div>
            <img
              width="20"
              height="20"
              src="gear.png"
              alt="More"
              onClick={handleGoMBTI}
            />
          </div>

          <section className="w-full">
            <h2 className="text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] mb-4">
              주제
            </h2>
            <div className="flex flex-col">
              {topics.length === 0 ? (
                <div className="flex justify-center items-center">
                  <BeatLoader
                    color="#3677f6"
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                topics.map((topic, index) => (
                  <div
                    key={index}
                    className="w-full border-b border-[#e9ecef] dark:border-[#666b81] bg-[#fff] dark:bg-[#22232a]"
                  >
                    <button
                      onClick={() => handleToggle(index)}
                      className={`flex justify-between items-center w-full p-4 border-b border-[#e9ecef] dark:border-[#666b81] last:border-none ${
                        expandedIndex !== index &&
                        selectedGenders.length > 0 &&
                        expandedIndex2 !== index
                          ? darkMode
                            ? "text-[#555]"
                            : "text-[#ccc]"
                          : darkMode
                          ? "text-[#fff]"
                          : "text-[#000]"
                      }`}
                    >
                      <span className="text-[16px] font-sans font-bold ">
                        {topic.title}
                      </span>
                      <img
                        width="30"
                        height="30"
                        src={`open.png`}
                        alt="Toggle"
                        className={`transition-transform duration-300 ${
                          expandedIndex === index ? "rotate-180" : "rotate-0"
                        } ${darkMode ? "filter invert" : ""}`}
                      />
                    </button>
                    {expandedIndex === index && (
                      <div className="p-4 bg-[#fff] dark:bg-[#22232a]">
                        {/* 메모 */}
                        <div className="text-[14px] font-sans">
                          {topic.content}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </section>
        </main>
        {selectedGenders.length > 0 ? (
          <footer className="fixed w-full bottom-0 py-4 px-4">
            <button
              className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
              onClick={handleNextClick2}
              // onClick={handleNextClick}
            >
              완료
            </button>
          </footer>
        ) : (
          <footer className="w-full bottom-0 py-4 px-4">
            <button
              className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
              onClick={handleNextClick2}
              // onClick={handleNextClick}
            >
              완료
            </button>
          </footer>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default MbtiStudy;
