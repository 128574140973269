import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setManageID, setImageUrl, setWhere } from "./actions";
import kor_eng from "../data/select_kor_eng.json";
import { FaTrash, FaPencilAlt, FaMinus } from "react-icons/fa"; // Import icons

const StoryBoard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [storyboards, setStoryboards] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Track the storyboard being edited
  const [editMode, setEditMode] = useState(false); // Toggle for edit mode
  const [newTitle, setNewTitle] = useState(""); // New title for editing

  // Function to retrieve items from localStorage
  const fetchStoryboards = () => {
    const storyboardData = [];
    let i = 1;
    while (localStorage.getItem(`storyboard${i}`)) {
      const items = JSON.parse(localStorage.getItem(`storyboard${i}`));
      const title = localStorage.getItem(`title${i}`) || `스토리보드${i}`;
      storyboardData.push({
        title,
        items,
      });
      i++;
    }
    setStoryboards(storyboardData);
  };

  useEffect(() => {
    fetchStoryboards();
  }, []);

  const handleGoBack = () => {
    navigate("/study");
  };

  const handleGoStudy = (item) => {
    if (item.expressions) {
      navigate(`/vocablist/${item.id}`);
    }
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    dispatch(setWhere("storyboard"));

    const fetchData = async () => {
      try {
        const languages = ["kor", "eng"];
        const difficulties = ["easyId", "normalId", "difficultId"];
        const fetchedLis = {};

        for (const language of languages) {
          fetchedLis[language] = {};
          const languageItem = item.customizes.find(
            (cust) => cust.language === language
          );

          if (languageItem) {
            for (const difficulty of difficulties) {
              const id = languageItem[difficulty];

              if (id) {
                const response = await fetch(
                  `${apiUrl}/customize_script/${id}`
                );
                const data = await response.json();

                if (response.ok) {
                  console.log(
                    `Fetched data for ${language} - ${difficulty}:`,
                    data
                  );
                  fetchedLis[language][difficulty] = data;
                }
              }
            }
          }
        }

        navigate("/studyonboard", {
          state: { script: fetchedLis, item: item },
        });
      } catch (error) {}
    };

    fetchData();
  };

  const handleEditClick = (index) => {
    setEditMode(!editMode); // Toggle edit mode
    setEditingIndex(index);
    setNewTitle(storyboards[index]?.title || "");
  };

  const handleSaveChanges = () => {
    // Save all changes to localStorage
    storyboards.forEach((storyboard, i) => {
      localStorage.setItem(
        `storyboard${i + 1}`,
        JSON.stringify(storyboard.items)
      );
      localStorage.setItem(`title${i + 1}`, storyboard.title); // Save title
    });
    setEditMode(false);
    setEditingIndex(null);
  };

  const handleDeleteStoryboard = (index) => {
    // Remove the storyboard from the state
    const updatedStoryboards = storyboards.filter((_, i) => i !== index);
    setStoryboards(updatedStoryboards);

    // Clear the deleted storyboard's keys from localStorage
    localStorage.removeItem(`storyboard${index + 1}`);
    localStorage.removeItem(`title${index + 1}`);

    // Re-index remaining storyboards in localStorage
    updatedStoryboards.forEach((storyboard, i) => {
      localStorage.setItem(
        `storyboard${i + 1}`,
        JSON.stringify(storyboard.items)
      );
      localStorage.setItem(`title${i + 1}`, storyboard.title); // Save updated title
    });

    // Clear any remaining keys in localStorage that may not be in the updated array
    const totalStoryboards = storyboards.length;
    for (let i = updatedStoryboards.length; i < totalStoryboards; i++) {
      localStorage.removeItem(`storyboard${i + 1}`);
      localStorage.removeItem(`title${i + 1}`);
    }
  };

  const handleEditTitle = (index) => {
    // Update the title of the specific storyboard in state
    const updatedStoryboards = [...storyboards];
    updatedStoryboards[index].title = newTitle;
    setStoryboards(updatedStoryboards);

    // Save the updated title to localStorage
    localStorage.setItem(`title${index + 1}`, newTitle);
  };

  const handleDeleteItem = (storyboardIndex, itemIndex) => {
    // Remove the specific item from the storyboard
    const updatedStoryboards = [...storyboards];
    updatedStoryboards[storyboardIndex].items.splice(itemIndex, 1);
    setStoryboards(updatedStoryboards);
    localStorage.setItem(
      `storyboard${storyboardIndex + 1}`,
      JSON.stringify(updatedStoryboards[storyboardIndex].items)
    );
  };

  useEffect(() => {
    const fetchTagData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {}
    };

    fetchTagData();
  }, [apiUrl]);

  const findTagById = (id, node) => {
    if (!node) return null;
    if (node.id === id) return node;
    if (node.children) {
      for (const child of node.children) {
        const result = findTagById(id, child);
        if (result) return result;
      }
    }
    return null;
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            {translations.STORYBOARD}
          </h1>
          <div className="flex items-center space-x-1">
            <button
              className="text-white text-[10px] bg-green-500 hover:bg-green-700 px-2 py-2 rounded-full"
              onClick={() =>
                editMode ? handleSaveChanges() : handleEditClick(null)
              }
            >
              {editMode ? translations.COMPLETE : translations.MODIFY}
            </button>
            <button
              className="text-white text-[10px] bg-blue-500 hover:bg-blue-700 px-2 py-2 rounded-full"
              onClick={() => navigate("/storyboardcreate")}
            >
              {translations.CREATE}
            </button>
          </div>
        </header>

        <div className="p-4">
          {storyboards.length === 0 ? (
            <p>No storyboards available</p>
          ) : (
            storyboards.map((storyboard, index) => (
              <div key={index} className="mb-8">
                <div className="flex items-center gap-2">
                  {editingIndex === index && editMode ? (
                    <input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onBlur={() => handleEditTitle(index)}
                      className="border rounded p-2"
                    />
                  ) : (
                    <h2 className="text-lg font-bold mb-4">
                      {storyboard.title}
                    </h2>
                  )}
                  {editMode && (
                    <>
                      <FaPencilAlt
                        className="text-gray-500 cursor-pointer"
                        onClick={() => {
                          setEditingIndex(index);
                          setNewTitle(storyboard.title);
                        }}
                      />
                      <FaTrash
                        className="text-red-500 cursor-pointer"
                        onClick={() => handleDeleteStoryboard(index)}
                      />
                    </>
                  )}
                </div>
                {storyboard.items.map((item, idx) => (
                  <div
                    key={idx}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0 cursor-pointer"
                    onClick={() => handleGoStudy(item)}
                  >
                    <div className="flex flex-col gap-[17px]">
                      <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] break-all">
                        {item.customizes
                          ? item.customizes.find(
                              (customize) => customize.language === "eng"
                            )?.title || "No English title available"
                          : item.situations
                          ? item.situations.find(
                              (customize) => customize.language === "eng"
                            )?.title || "No English title available"
                          : item.psychologys
                          ? item.psychologys.find(
                              (customize) => customize.language === "eng"
                            )?.title || "No English title available"
                          : item.expressions.find(
                              (customize) => customize.language === "eng"
                            )?.expressive_language ||
                            "No English title available"}
                      </div>
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] break-all">
                        {item.customizes
                          ? item.customizes.find(
                              (customize) => customize.language === "kor"
                            )?.title || "No English title available"
                          : item.situations
                          ? item.situations.find(
                              (customize) => customize.language === "kor"
                            )?.title || "No English title available"
                          : item.psychologys
                          ? item.psychologys.find(
                              (customize) => customize.language === "kor"
                            )?.title || "No English title available"
                          : item.expressions.find(
                              (customize) => customize.language === "kor"
                            )?.expressive_language ||
                            "No English title available"}
                      </div>

                      {/* Display tags */}
                      <div className="flex items-center gap-[3px] mt-2">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {findTagById(tag.id, data)?.translations.find(
                                  (t) => t.language === language
                                )?.name || "No tag available"}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryBoard;
