import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher3 from "./LanguageSwitcher3";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StudyQuitModal from "./StudyQuitModal";
import { useDifficulty } from "./DifficultyContext";
import BookmarkModal from "./BookmarkModal";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import kor_eng from "../data/select_kor_eng.json";

const StudyPoint = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { script, id } = location.state || {};
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { difficulty, setDifficulty } = useDifficulty();
  const type = useSelector((state) => state.type);
  const accessToken = sessionStorage.getItem("accessToken");
  const manageId = useSelector((state) => state.manageId);
  const where = useSelector((state) => state.where);
  const [bookmarkId, setBookmarkId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleSwipeLeft = () => {
    handleGoNext();
  };

  const handleSwipeRight = () => {
    handleGoBack();
  };
  UseSwipe(handleSwipeLeft, handleSwipeRight);
  const ProgressBar = ({ filledSections }) => {
    const totalSections = 5;
    const gapWidth = 4;

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };
  // first overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    if (difficulty === "E") {
      setDifficulty("easyId");
    } else if (difficulty === "N") {
      setDifficulty("normalId");
    } else {
      setDifficulty("difficultId");
    }
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (difficulty) {
      case "easyId":
        return "E.svg";
      case "normalId":
        return "N.svg";
      case "difficultId":
        return "D.svg";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  useEffect(() => {
    if (isModalVisible2) {
      const timer = setTimeout(() => {
        setisModalVisible2(false);
      }, 2000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isModalVisible2]);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const fetchData = async () => {
    try {
      let url;
      if (type === "mbti" || type === "persona") {
        url = `${apiUrl}/customize_quiz?scriptId=${script[language][difficulty].id}`;
      } else {
        url = `${apiUrl}/${type}_quiz?scriptId=${script[language][difficulty].id}`;
      }
      const response = await fetch(url);
      const fetchedData = await response.json();

      // Find index of the "ox" type item
      const oxIndex = fetchedData.findIndex((item) => item.type === "ox");

      // Check if the item exists
      if (oxIndex !== -1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {}
  };

  const handleGoNext = async () => {
    if (await fetchData()) {
      navigate("/quiza", { state: { script: script, id: id } });
    } else {
      navigate("/quizb", { state: { script: script, id: id } });
    }
  };
  const handleGoStudy = () => {
    if (where === "study") {
      navigate(`/${type}studylist`);
    } else if (where === "home") {
      navigate("/home");
    } else {
      navigate(`/bookmark`);
    }
  };
  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'

  useEffect(() => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const fetchData = async () => {
      const response = await fetch("https://pastellapp.com/api/bookmarks/", {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const data3 = await response.json();

        // Check if itemId 5 is in the results array
        const itemFound = data3.results.some(
          (item) => item.itemId === manageId && item.itemType === type2
        );

        // Update the selectedMark state based on whether the item is found
        setSelectedMark(itemFound);
      } else {
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const updateBookmark = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const postData = {
      itemId: parseInt(manageId, 10),
      itemType: type2,
    };

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
      body: JSON.stringify(postData), // Convert the data to JSON string
    });

    if (response.ok) {
      const data3 = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const findId = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data3 = await response.json();

      const itemFound = data3.results.find(
        (item) => item.itemId === manageId && item.itemType === type2
      );

      if (itemFound) {
        return new Promise((resolve) => {
          setBookmarkId(itemFound.id);
          resolve(itemFound.id); // Resolve with the found id
        });
      } else {
        return Promise.reject("Item not found"); // Reject the promise if not found
      }
    } else {
      return Promise.reject("Failed to fetch data"); // Reject the promise if fetch fails
    }
  };

  const deleteBookmark = async (id) => {
    const response = await fetch(`https://pastellapp.com/api/bookmarks/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
    });

    if (response.ok) {
      const data3 = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const handleMarkAction = async () => {
    if (!selectedMark) {
      await updateBookmark();
      setSelectedMark(true);
      setShowModal(true);
    } else {
      try {
        const id = await findId(); // Wait for findId to resolve with the bookmarkId
        await deleteBookmark(id); // Pass the bookmarkId to deleteBookmark
        setSelectedMark(false);
      } catch (error) {}
    }
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  // State to track the current selected image ("x" or "o") or none
  const [selected, setSelected] = useState(null);

  // Handle clicking on an image
  const handleClick = (image) => {
    if (selected === image) {
      // If the same image is clicked again, toggle it back to "before" state
      setSelected(null);
    } else {
      // Otherwise, set the clicked image as the selected one
      setSelected(image);
    }
  };
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const oppositeLanguage = language === "kor" ? "eng" : "kor";
        let url2;
        if (type === "mbti" || type === "persona") {
          url2 = `${apiUrl}/customize_studypoint?scriptId=${script[language][difficulty].id}`;
        } else {
          url2 = `${apiUrl}/${type}_studypoint?scriptId=${script[language][difficulty].id}`;
        }
        const response = await fetch(url2);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl, language, difficulty]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const oppositeLanguage = language === "kor" ? "eng" : "kor";
        let url3;
        if (type === "mbti" || type === "persona") {
          url3 = `${apiUrl}/customize_studypoint?scriptId=${script[oppositeLanguage][difficulty].id}`;
        } else {
          url3 = `${apiUrl}/${type}_studypoint?scriptId=${script[oppositeLanguage][difficulty].id}`;
        }
        const response2 = await fetch(url3);
        const fetchedData2 = await response2.json();
        setData2(fetchedData2); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl, language, difficulty]);

  return (
    <div
      id="swipe-container"
      className="flex min-h-screen justify-center w-full bg-[#faf8f6]"
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      {isOverlayVisible2 && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="relative w-[100%] bg-[#faf8f6]">
        <div className="absolute left-0 top-[83%] w-full flex flex-col items-center justify-center">
          <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoBack}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="fb.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={toggleOverlay2}
            >
              <div className="absolute left-0 top-0 flex flex-col items-center justify-start gap-[8px]">
                <div className="relative w-[50px] h-[50px] shrink-0">
                  <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                  <img
                    className="absolute -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2"
                    width="24"
                    src={getImageSrc()}
                  ></img>
                </div>
                <div className="self-stretch text-[12px] leading-[100%] font-sans text-[#000] text-center">
                  {translations.DIFFICULTY}
                </div>
              </div>
            </div>

            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={handleMarkAction}
            >
              <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
              <div className="absolute left-1/2 top-[57px] w-[52px] h-[12px] text-[12px] leading-[100%] font-sans text-[#000] text-center transform -translate-x-1/2">
                {translations.BOOKMARK}
              </div>
              <img
                className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[calc(50%+-9px)]"
                width="13"
                height="24"
                src={getMarkImage()}
              />
            </div>
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoNext}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="ff.png"
              ></img>
            </div>
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[10px] w-full h-[52px] overflow-hidden">
          {/* Header */}
          <div className="absolute top-0 left-0 right-0 flex items-center justify-between px-[16px]">
            {/* Left: xbutton */}
            <div className="w-[36px] flex items-center">
              <div className="relative w-[36px] h-[36px]">
                <img
                  className="w-full h-full"
                  src="xbutton.png"
                  alt="Close Button"
                  onClick={toggleModal}
                />
              </div>
            </div>
            {/* Center: Quiz Text */}
            <div className="text-[16px] font-sans font-semibold text-[#000]">
              Study point
            </div>
            {/* Right: ddd2 Image */}
            <div className="w-[28px] flex items-center justify-end">
              <img
                width="28"
                height="28"
                src="ddd2.png"
                alt="Overlay Toggle"
                onClick={toggleOverlay}
              />
            </div>
          </div>
          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 w-full">
            <ProgressBar filledSections={2} />
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[60px] w-[91%] h-[60%]">
          <div className="absolute left-[89%] top-[2%] flex flex-row items-center justify-start z-10">
            <LanguageSwitcher3></LanguageSwitcher3>
          </div>
          <div className="absolute top-[7vh] w-[100%] flex flex-col items-start justify-start gap-[25px]">
            {/* Combine Data from Both Arrays */}

            {Array.isArray(data) ? (
              data.map((item, index) => (
                <div
                  key={index}
                  className="self-stretch flex flex-col items-start justify-start gap-[10px] py-[12px] px-[16px] bg-[#fff] rounded-[8px]"
                >
                  <div className="flex flex-row items-center justify-center">
                    <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] whitespace-nowrap">
                      {item.title}
                    </div>
                  </div>
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                    {data2[index] ? data2[index].title : "Title not available"}
                  </div>
                  <div className="self-stretch flex flex-row items-center justify-center py-0 border-t border-[#e0e0e0]">
                    <div
                      className="flex-1 text-[14px] leading-[22px] font-sans text-[#000]"
                      dangerouslySetInnerHTML={{
                        __html: data2[index]
                          ? data2[index].content
                          : "Content not available",
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div>loading...</div>
            )}
          </div>
        </div>

        {/* Overlay */}
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
              onClick={() => navigate("/langsetting")}
            >
              {translations.LANGUAGE_SETTINGS}
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 2 */}
            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
              onClick={() => navigate("/error-action")}
            >
              {translations.REPORT_ERROR}
            </div>
          </div>
        </div>

        {/* Overlay2 */}
        <div
          ref={overlayRef2}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible2
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay2 */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              난이도
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("E")}
            >
              EASY
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("N")}
            >
              NORMAL
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("D")}
            >
              DIFFICULT
            </div>
          </div>
        </div>

        <StudyQuitModal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onAction1={handleModalAction1}
          onAction2={handleGoStudy}
        />
        <BookmarkModal show={showModal} onClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default StudyPoint;
