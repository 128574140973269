import React from "react";
import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";
import kor_eng from "../data/select_kor_eng.json";
import { FaTimes } from "react-icons/fa";

const PersonaStudylist = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const keywords = useSelector((state) => state.keywords);
  const lis = useSelector((state) => state.lis);
  const dispatch = useDispatch();
  //
  const { translations, language } = useContext(LanguageContext);
  const [data, setData] = useState({});
  const [language2, setLanguage2] = useState("");
  const [toggleFilter, setToggleFilter] = useState(true); // Track the filter state
  const [visibleItems, setVisibleItems] = useState(
    () => parseInt(sessionStorage.getItem("visibleItems"), 10) || 10
  ); // Start by showing 10 items
  const scrollableDivRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedLis, setSortedLis] = useState([]);

  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => {
      const newVisibleItems = prevVisibleItems + 10;
      sessionStorage.setItem("visibleItems", newVisibleItems); // Save the updated value
      return newVisibleItems;
    });
  };
  const navigate = useNavigate();

  useEffect(() => {
    setToggleFilter((prevToggle) => !prevToggle); // Toggle the state
    if (language === "eng") {
      setLanguage2("kor");
    } else {
      setLanguage2("eng");
    }
  }, [language]);

  const handleGoBack = () => {
    if (sessionStorage.getItem("aswh") === "starscore") {
      sessionStorage.removeItem("aswh");
      navigate(-1);
    } else navigate("/situationstudy");
  };

  // Delay scroll restoration until content is rendered
  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition && scrollableDivRef.current) {
      setTimeout(() => {
        scrollableDivRef.current.scrollTop = parseInt(savedScrollPosition, 10); // Set scroll position of div after content loads
      }, 100); // Adding a delay to ensure content is loaded before setting scroll position
    }
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("visibleItems");
  }, [sortedLis]);
  const handleGoStudy = (item) => {
    sessionStorage.setItem(
      "scrollPosition",
      scrollableDivRef.current.scrollTop
    );
    sessionStorage.setItem("visibleItems", visibleItems);
    sessionStorage.setItem(
      "itemId",
      item.situations.filter(
        (customizes) => customizes.language === language
      )[0].id
    );
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    dispatch(setWhere("study"));
    const fetchData = async () => {
      try {
        const languages = ["kor", "eng"];
        const difficulties = ["easyId", "normalId", "difficultId"];
        const fetchedLis = {};

        // Initialize fetchedLis for each language
        for (const language of languages) {
          fetchedLis[language] = {};
          // Find the corresponding item for the language
          const languageItem = item.situations.find(
            (cust) => cust.language === language
          );

          if (languageItem) {
            // Fetch data for each difficulty
            for (const difficulty of difficulties) {
              const id = languageItem[difficulty];

              if (id) {
                const response = await fetch(
                  `${apiUrl}/situation_script/${id}`
                );
                const data = await response.json();

                if (response.ok) {
                  console.log(
                    `Fetched data for ${language} - ${difficulty}:`,
                    data
                  );
                  fetchedLis[language][difficulty] = data;
                }
              }
            }
          }
        }

        // After fetching all data, navigate and pass the collected data
        dispatch(setType("situation"));
        // dispatch(setScript(fetchedLis));
        navigate("/studyonboard", {
          state: { script: fetchedLis, item: item },
        });
      } catch (error) {}
    };

    fetchData();
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/2`);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if the current node's id matches the id being searched
    if (node.id === id) return node;

    // Recursively check through children if available
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };

  const sortData = useCallback(
    (dataToSort) => {
      // 정렬 전 데이터 로그
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? b.id - a.id
            : a.id - b.id;
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle =
            a.situations.find((cust) => cust.language === language)?.title ||
            "";
          const bTitle =
            b.situations.find((cust) => cust.language === language)?.title ||
            "";
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.averageRating - b.averageRating
            : b.averageRating - a.averageRating;
        });
      }
      // 정렬 후 데이터 로그
      return sortedData;
    },
    [sortConfig, language]
  );

  const handleSort = (key) => {
    // 정렬 키 로그
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    // 현재 lis 상태 로그
    const sortedData = sortData(lis);
    // 정렬 후 새 데이터 로그
    setSortedLis(sortedData);
  }, [lis, sortConfig, sortData]);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        {/* Updated Item list Section */}
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            {/* <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            /> */}
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={30}
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] text-center">
            Situation
          </h1>
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </header>
        {/* Top Bar */}
        <div
          className={`w-full h-[44px] flex items-center justify-between px-4 ${
            darkMode
              ? "bg-[#22232a]"
              : "bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)]"
          }`}
        >
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center justify-center py-[5px] px-[8px] bg-[#fff] dark:bg-[#31333e] rounded-full">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] dark:text-[#a9b6ca] whitespace-nowrap">
                KEYWORD
              </div>
            </div>
            <div className="flex items-center gap-[4px]">
              {keywords
                .filter((_, index) => {
                  if (toggleFilter) {
                    // Toggle between even and odd indices based on the toggleFilter state
                    return index % 2 === 0;
                  } else {
                    // Toggle between even and odd indices based on the toggleFilter state
                    return index % 2 !== 0;
                  }
                })
                .map((keyword, index) => (
                  <React.Fragment key={index}>
                    <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                      {keyword}
                    </div>
                    {index < keywords.length / 2 - 1 && ( // Check if it's not the last item to add the dot
                      <div className="w-[2px] h-[2px] bg-[#fff] rounded-full"></div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>

        {/* Sorting Options */}
        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "latest"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("latest")}
            >
              {translations.ORDER_DATE}{" "}
              {sortConfig.key === "latest" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "alphabetical"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("alphabetical")}
            >
              {translations.ORDER_AL}{" "}
              {sortConfig.key === "alphabetical" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "rating"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("rating")}
            >
              {translations.ORDER_RA}{" "}
              {sortConfig.key === "rating" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
          </div>
        </div>

        {/* Content */}
        <div
          ref={scrollableDivRef}
          className="w-full h-[calc(100vh-140px)] flex flex-col items-start bg-[#fff] dark:bg-[#31333e] overflow-y-auto"
        >
          {/* 카드 */}
          {sortedLis.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-full w-full text-center p-4">
              <div className="text-gray-500 text-lg mb-2">
                {translations.list1}
              </div>
              <FaTimes className="text-gray-500 text-3xl" />
            </div>
          ) : (
            sortedLis.slice(0, visibleItems).map((item, index) => {
              // Check if `situations` is defined and has at least 2 elements
              if (item.situations && item.situations.length > 1) {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                    onClick={() => handleGoStudy(item)}
                  >
                    {/* Titles */}
                    <div className="flex flex-col gap-[17px]">
                      <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center">
                          {/* English title */}
                          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-words">
                            {item.situations.find(
                              (customize) => customize.language === language
                            )?.title || "No title available"}{" "}
                            {/* English title */}
                          </div>
                        </div>
                        {/* Korean title */}
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-words">
                          {item.situations.find(
                            (customize) => customize.language === language2
                          )?.title || "No title available"}{" "}
                          {/* Korean title */}
                        </div>
                      </div>

                      {/* Tags */}
                      <div className="flex items-center gap-[3px]">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {data
                                  ? findItemById(
                                      tag.id,
                                      data
                                    )?.translations.find(
                                      (t) => t.language === language
                                    )?.name
                                  : "Loading..."}
                              </div>
                              {tagIndex < item.tag.length - 1 && (
                                <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Rating */}
                    <div className="flex items-center gap-[4px]">
                      <img
                        className={darkMode ? "filter invert" : ""}
                        width="15"
                        height="15"
                        src={`star.png`}
                        alt="rating star"
                      />
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap">
                        {item.averageRating.toFixed(1)}{" "}
                        {/* Display the rating */}
                      </div>
                    </div>
                  </div>
                );
              } else {
                // Optionally handle cases where `item.situations` is not as expected
                // return (
                //   <div
                //     key={index}
                //     className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0"
                //   >
                //     <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333]">
                //       Data missing
                //     </div>
                //   </div>
                // );
              }
            })
          )}
          {/* Load More Button */}
          {visibleItems < lis.length && (
            <div className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] w-[100%]">
              <button
                onClick={loadMoreItems}
                className="px-4 py-2 bg-[#007bff] text-white rounded"
              >
                더보기
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonaStudylist;
