import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "./actions";
import { LanguageContext } from "./LanguageContext"; // Import the Language context
import { IoIosArrowBack } from "react-icons/io";
import kor_eng from "../data/select_kor_eng.json";

const LangSetting = () => {
  const { translations } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const [selectedOption, setSelectedOption] = useState(
    userInfo.nativeLanguage === "eng" ? "English" : "한국어"
  );
  const [selectedOption2, setSelectedOption2] = useState(
    userInfo.targetLanguage === "eng" ? "English" : "한국어"
  );
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  // Import language context

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const handleToggle2 = () => {
    setIsOpen2((prev) => !prev);
  };

  const handleOptionClick2 = (option) => {
    setSelectedOption2(option);
    setIsOpen2(false);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleStart = () => {
    if (
      selectedOption !== "선택해주세요" &&
      selectedOption2 !== "선택해주세요"
    ) {
      navigate("/accountinfo");
    } else {
      toast.error("모든 옵션을 선택해 주세요.");
    }
  };

  const fetchInfo = async (accessToken) => {
    try {
      const response = await fetch(`${apiUrl}/auth/user-info`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();
        dispatch(setUserInfo(result));
        //
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle request error
    }
  };

  async function refreshAccessToken() {
    try {
      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: userInfo.userid, // Pass userId or other required params
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        return tokenData; // Contains new accessToken and refreshToken
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  const updateProfile = async () => {
    const data = {
      userid: userInfo.userid,
      nickname: userInfo.nickname,
      nativeLanguage: selectedOption === "English" ? "ENG" : "KOR",
      targetLanguage: selectedOption2 === "English" ? "ENG" : "KOR",
      mbti: userInfo.mbti,
      sex: userInfo.sex,
      age: userInfo.age,
      job: userInfo.job,
      love: userInfo.love,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();

        fetchInfo(accessToken);

        const refreshedTokenData = await refreshAccessToken();
        if (refreshedTokenData) {
          // Save the new tokens in sessionStorage
          sessionStorage.setItem("accessToken", refreshedTokenData.accessToken);
          localStorage.setItem("refreshToken", refreshedTokenData.refreshToken);

          // Navigate to home after successful profile update and token refresh
          navigate(-1);
        } else {
          alert("Failed to refresh token.");
        }
      } else {
        // alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          {translations.langsettings1}
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 px-4 py-[20px] flex flex-col gap-5">
        {/* Language Selection */}
        <div className="flex flex-col gap-5">
          {/* Native Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[18px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000] dark:text-[#fff]">
              모국어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000] dark:text-[#fff]">
              Native Language
            </div>
            <div className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px] dark:bg-[#22232a] dark:border-none">
              <div className="flex-1 text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                {selectedOption}
              </div>
              <button
                className="w-[30px] h-[30px] flex items-center justify-center"
                onClick={handleToggle}
              >
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#d5dce4] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {["English", "한국어"].map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Target Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[18px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000] dark:text-[#fff]">
              학습언어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000] dark:text-[#fff]">
              Target Language
            </div>
            <div className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px] dark:bg-[#22232a] dark:border-none">
              <div className="flex-1 text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                {selectedOption2}
              </div>
              <button
                onClick={handleToggle2}
                className="w-[30px] h-[30px] flex items-center justify-center"
              >
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen2 && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#000] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {["English", "한국어"].map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick2(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="w-full h-[48px] flex items-center justify-between py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                선택해주세요
              </div>
              <img
                width="30"
                height="30"
                src="iconI5_75;189_11838;198_4987.png"
                alt="icon"
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]" onClick={updateProfile}>
        <button className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold">
          {translations.acsettings9}
        </button>
      </div>
      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default LangSetting;
