import bgImage1 from "./default1.webp";
import bgImage2 from "./default2.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import StudyQuitModal from "./StudyQuitModal";
import { useDispatch, useSelector } from "react-redux";
import { useDifficulty } from "./DifficultyContext";
import BookmarkModal from "./BookmarkModal";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

const Conversation = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { script, id } = location.state || {};

  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const type = useSelector((state) => state.type);
  const imageUrl = useSelector((state) => state.imageUrl);
  const where = useSelector((state) => state.where);
  const { difficulty, setDifficulty } = useDifficulty();

  const handleSwipeLeft = () => {
    handleGoNext();
  };

  const handleSwipeRight = () => {
    handleGoBack();
  };

  const boysName = [
    "태오",
    "주호",
    "민우",
    "지훈",
    "준호",
    "현우",
    "민준",
    "서준",
    "도윤",
    "태민",
    "예준",
    "우진",
    "성민",
    "찬호",
    "영민",
    "우빈",
    "재민",
    "지호",
    "승현",
    "동현",
    "태현",
    "하준",
    "주원",
    "하민",
    "형준",
    "수현",
    "지환",
    "경민",
    "정우",
    "준영",
    "성훈",
    "승우",
    "기현",
    "서진",
    "현민",
    "명훈",
    "성준",
    "희준",
    "은우",
    "재하",
    "동우",
    "찬우",
    "승민",
    "민재",
    "윤호",
    "성우",
    "찬민",
    "태우",
    "민수",
    "재현",
    "영훈",
    "성현",
    "현서",
    "준혁",
    "태훈",
    "민석",
    "도현",
    "동훈",
    "재우",
    "주현",
    "태영",
    "동건",
    "형우",
    "민혁",
    "지혁",
    "기훈",
    "시우",
    "승호",
    "건우",
    "석민",
    "정민",
    "경준",
    "재훈",
    "민기",
    "성빈",
    "민호",
    "동희",
    "현석",
    "태준",
    "지성",
    "희성",
    "우현",
    "하늘",
    "우석",
    "석훈",
    "기범",
    "찬희",
    "유민",
    "형석",
    "원우",
    "찬영",
    "세준",
    "진우",
    "정훈",
    "재성",
    "현빈",
    "정현",
    "수호",
    "건희",
    "태경",
    "도훈",
    "명우",
    "도우",
    "병훈",
    "승훈",
    "동호",
    "태성",
    "재호",
    "수민",
    "인우",
    "종현",
    "은찬",
    "기성",
    "동혁",
    "준성",
    "원준",
    "성진",
    "상현",
    "승준",
    "영진",
    "세현",
    "정진",
    "명훈",
    "태범",
    "동준",
    "한결",
    "현승",
    "상우",
    "세영",
    "태건",
    "우림",
    "진성",
    "현도",
    "종민",
    "태수",
    "광민",
    "상민",
    "성균",
    "재원",
    "영준",
    "도영",
    "지웅",
    "성운",
    "창민",
    "상준",
    "동규",
    "한서",
    "동윤",
    "동수",
    "성욱",
    "정태",
    "우진",
    "진호",
    "주성",
    "광훈",
    "현찬",
    "명현",
    "수진",
    "경우",
    "지혁",
    "영우",
    "동진",
    "종우",
    "건호",
    "준우",
    "준기",
    "종혁",
    "정훈",
    "기영",
    "찬희",
    "승재",
    "정수",
    "세준",
    "주찬",
    "창훈",
    "정민",
    "영규",
    "종훈",
    "병민",
    "진혁",
    "태일",
    "경빈",
    "용준",
    "준태",
    "정찬",
    "병준",
    "진우",
    "석진",
    "진영",
    "태양",
    "진현",
    "상훈",
    "정석",
    "진명",
    "재준",
    "용민",
    "준서",
    "용현",
    "윤기",
    "영일",
    "준환",
    "석우",
    "경훈",
    "하람",
    "정규",
    "시헌",
    "찬호",
    "경호",
    "동규",
    "도현",
    "준영",
    "승건",
    "세용",
    "지훈",
    "태진",
    "용훈",
    "현철",
    "현기",
    "영현",
    "수환",
    "상윤",
    "승진",
    "성진",
    "진형",
    "기찬",
    "동찬",
    "재웅",
    "진석",
    "원빈",
    "도건",
    "승준",
    "영석",
    "정수",
    "종서",
    "정혁",
    "태용",
    "동욱",
    "재형",
    "희민",
    "태영",
    "기태",
    "지형",
    "재중",
    "태식",
    "진호",
    "기우",
    "태겸",
    "희찬",
    "희수",
    "준태",
    "정익",
    "시완",
    "상기",
    "기용",
    "상건",
    "우주",
    "시온",
    "주헌",
    "영규",
    "성찬",
    "태완",
    "성연",
    "기명",
    "병진",
    "승원",
    "재민",
    "광수",
    "진국",
    "형수",
    "형민",
    "서호",
    "유겸",
    "승연",
    "도형",
    "하영",
    "경호",
    "현상",
    "도준",
    "상욱",
    "경식",
    "동민",
    "경수",
    "영민",
    "수혁",
    "용호",
    "종구",
    "민형",
    "성민",
    "기민",
    "유한",
    "광훈",
    "주혁",
    "병호",
    "규원",
    "기석",
    "준형",
    "희규",
    "태화",
    "우람",
    "상범",
    "동운",
    "경수",
    "동혁",
    "진욱",
    "주한",
    "광진",
    "도승",
    "유건",
    "상헌",
    "재한",
    "창현",
    "동우",
    "주안",
    "재욱",
    "기훈",
    "태호",
    "민중",
    "진원",
    "하율",
    "우섭",
    "기현",
    "성기",
    "정권",
    "태민",
    "종혁",
    "성호",
    "경헌",
    "정하",
    "영빈",
    "기철",
    "상진",
    "현성",
    "영기",
    "현웅",
    "정빈",
    "재하",
    "정윤",
    "세광",
    "영수",
    "성용",
    "하담",
    "정용",
    "영모",
    "기범",
    "진명",
    "병수",
    "종수",
    "경완",
    "기용",
    "영재",
    "준해",
    "유일",
    "석현",
    "동건",
    "명빈",
    "태윤",
    "종빈",
    "규진",
    "동엽",
    "지백",
    "석연",
    "병찬",
    "명기",
    "승서",
    "병기",
    "우연",
    "성수",
    "석태",
    "진성",
    "병훈",
    "우창",
    "기범",
    "태룡",
    "태헌",
    "병식",
    "재형",
    "성권",
    "상명",
    "동식",
    "명현",
    "하온",
    "영관",
    "정연",
    "병석",
    "준식",
    "태훈",
    "동후",
    "하람",
    "태건",
    "도빈",
    "찬희",
    "성언",
    "석규",
    "정운",
    "민철",
    "상원",
    "경수",
    "영선",
    "정석",
    "병연",
    "수영",
    "태은",
    "경욱",
    "정헌",
    "병선",
    "도영",
    "동진",
    "기영",
    "상원",
    "광명",
    "동완",
    "지섭",
    "성화",
    "하연",
    "민태",
    "태성",
    "승제",
    "준엽",
    "재홍",
    "상윤",
    "영식",
    "현민",
    "진혁",
    "정섭",
    "석원",
    "태명",
    "우철",
    "정범",
    "태린",
    "진웅",
    "도경",
    "태산",
    "병무",
    "수범",
    "준기",
    "상우",
    "진중",
    "성주",
    "도혁",
    "병영",
    "태유",
    "상길",
    "도경",
    "세종",
    "현겸",
    "재혁",
    "승일",
    "태윤",
    "상협",
    "우영",
    "정건",
    "기광",
    "태섭",
    "진재",
    "영우",
    "정우",
    "성욱",
    "현오",
    "병하",
    "규범",
    "상호",
    "지율",
    "정성",
    "태림",
    "성기",
    "병훈",
    "진헌",
    "지후",
    "태승",
    "승제",
    "성건",
    "정균",
    "승일",
    "준호",
    "정영",
    "광훈",
    "태은",
    "도원",
    "우현",
    "명호",
    "하정",
    "상환",
    "정주",
    "승곤",
    "유환",
    "상규",
    "하준",
    "진정",
    "동철",
    "승범",
    "정재",
    "정원",
    "재하",
    "동근",
    "정환",
    "상기",
    "병태",
    "상규",
    "재은",
    "도현",
    "명석",
    "병선",
    "명철",
  ];
  const boysNameEng = [
    "Taeo",
    "Juho",
    "Minwoo",
    "Jihun",
    "Junho",
    "Hyunwoo",
    "Minjun",
    "Seojun",
    "Doyoon",
    "Taemin",
    "Yejoon",
    "Woojin",
    "Sungmin",
    "Chanho",
    "Youngmin",
    "Woobin",
    "Jaemin",
    "Jiho",
    "Seunghyun",
    "Donghyun",
    "Taehyun",
    "Hajun",
    "Juwon",
    "Hamin",
    "Hyungjun",
    "Soohyun",
    "Jihwan",
    "Kyungmin",
    "Jungwoo",
    "Junyoung",
    "Sunghoon",
    "Seungwoo",
    "Kihyun",
    "Seojin",
    "Hyunmin",
    "Myunghoon",
    "Sungjun",
    "Heejun",
    "Eunwoo",
    "Jaeha",
    "Dongwoo",
    "Chanwoo",
    "Seungmin",
    "Minjae",
    "Yoonho",
    "Sungwoo",
    "Chanmin",
    "Taewoo",
    "Minsu",
    "Jaehyun",
    "Younghoon",
    "Sunghyun",
    "Hyunseo",
    "Junhyuk",
    "Taehoon",
    "Minseok",
    "Dohyun",
    "Donghoon",
    "Jaewoo",
    "Juhyun",
    "Taeyoung",
    "Donggeon",
    "Hyungwoo",
    "Minhyuk",
    "Jihyuk",
    "Kihun",
    "Siwoo",
    "Seungho",
    "Geonwoo",
    "Seokmin",
    "Jungmin",
    "Kyungjun",
    "Jaehun",
    "Mingi",
    "Sungbin",
    "Minho",
    "Donghee",
    "Hyunseok",
    "Taejun",
    "Jisung",
    "Heeseong",
    "Woohyun",
    "Haneul",
    "Wooseok",
    "Seokhoon",
    "Kibeom",
    "Chanhee",
    "Yoomin",
    "Hyungseok",
    "Wonwoo",
    "Chanyeong",
    "Sejun",
    "Jinwoo",
    "Junghun",
    "Jaesung",
    "Hyunbin",
    "Junghyun",
    "Suho",
    "Geonhee",
    "Taekyung",
    "Dohun",
    "Myungwoo",
    "Dowoo",
    "Byunghun",
    "Seunghun",
    "Dongho",
    "Taeseong",
    "Jaeho",
    "Soomin",
    "Inwoo",
    "Jonghyun",
    "Eunchan",
    "Kiseong",
    "Donghyuk",
    "Junseong",
    "Wonjun",
    "Sungjin",
    "Sanghyun",
    "Seungjun",
    "Youngjin",
    "Sehyun",
    "Jungjin",
    "Myunghun",
    "Taebeom",
    "Dongjun",
    "Hangyeol",
    "Hyunseung",
    "Sangwoo",
    "Seyoung",
    "Taegun",
    "Woorim",
    "Jinsung",
    "Hyundo",
    "Jongmin",
    "Taesoo",
    "Kwangmin",
    "Sangmin",
    "Sunggyun",
    "Jaewon",
    "Youngjun",
    "Doyeong",
    "Jiwoong",
    "Sungwoon",
    "Changmin",
    "Sangjun",
    "Donggyu",
    "Hanseo",
    "Dongyoon",
    "Dongsoo",
    "Seonguk",
    "Jungtae",
    "Woojin",
    "Jinho",
    "Juseong",
    "Kwanghoon",
    "Hyunchan",
    "Myunghyun",
    "Sujin",
    "Kyungwoo",
    "Jihyuk",
    "Youngwoo",
    "Dongjin",
    "Jongwoo",
    "Gunho",
    "Junwoo",
    "Jungi",
    "Jonghyuk",
    "Junghun",
    "Kiyoung",
    "Chanhee",
    "Seungjae",
    "Jungsoo",
    "Sejun",
    "Joochan",
    "Changhun",
    "Jungmin",
    "Youngkyu",
    "Jonghun",
    "Byungmin",
    "Jinhyuk",
    "Taeil",
    "Kyungbin",
    "Yongjun",
    "Juntae",
    "Jungchan",
    "Byungjun",
    "Jinwoo",
    "Seokjin",
    "Jinyoung",
    "Taeyang",
    "Jinhyun",
    "Sanghoon",
    "Jungseok",
    "Jinmyung",
    "Jaejun",
    "Yongmin",
    "Junseo",
    "Yonghyun",
    "Yunki",
    "Youngil",
    "Junhwan",
    "Seokwoo",
    "Kyunghun",
    "Haram",
    "Jungkyu",
    "Siheon",
    "Chanho",
    "Kyungho",
    "Donggyu",
    "Dohyun",
    "Junyoung",
    "Seunggeon",
    "Seyong",
    "Jihun",
    "Taejin",
    "Yonghun",
    "Hyuncheol",
    "Hyungi",
    "Younghyun",
    "Suhwan",
    "Sangyoon",
    "Seungjin",
    "Sungjin",
    "Jinhyung",
    "Kichan",
    "Dongchan",
    "Jaewoong",
    "Jinseok",
    "Wonbin",
    "Dogun",
    "Seungjun",
    "Youngseok",
    "Jungsoo",
    "Jongseo",
    "Junghyuk",
    "Taeyong",
    "Dongwook",
    "Jaehyung",
    "Heemin",
    "Taeyoung",
    "Kitae",
    "Jihyung",
    "Jaejoong",
    "Taeshik",
    "Jinho",
    "Kiu",
    "Taegyeom",
    "Heechan",
    "Heesoo",
    "Juntae",
    "Jungik",
    "Siwan",
    "Sanggi",
    "Kiyong",
    "Sanggeon",
    "Wooju",
    "Sion",
    "Juhun",
    "Youngkyu",
    "Seongchan",
    "Taewan",
    "Seongyeon",
    "Kimyeong",
    "Byungjin",
    "Seungwon",
    "Jaemin",
    "Kwangsoo",
    "Jinguk",
    "Hyungsu",
    "Hyungmin",
    "Seoho",
    "Yugyeom",
    "Seungyeon",
    "Dohyoung",
    "Hayoung",
    "Kyungho",
    "Hyunsang",
    "Dojun",
    "Sangwook",
    "Kyungsik",
    "Dongmin",
    "Kyungsu",
    "Youngmin",
    "Suhyuk",
    "Yongho",
    "Jonggu",
    "Minhyung",
    "Sungmin",
    "Kimmin",
    "Yuhan",
    "Kwanghoon",
    "Joohyuk",
    "Byungho",
    "Kyuwon",
    "Kiseok",
    "Junhyung",
    "Heegyu",
    "Taehwa",
    "Wooram",
    "Sangbeom",
    "Dongwoon",
    "Kyungsu",
    "Donghyuk",
    "Jinwook",
    "Joohan",
    "Kwangjin",
    "Doseung",
    "Yougeon",
    "Sangheon",
    "Jaehan",
    "Changhyun",
    "Dongwoo",
    "Jooan",
    "Jaewook",
    "Kihun",
    "Taeho",
    "Minjung",
    "Jinwon",
    "Hayul",
    "Wooseop",
    "Kihyun",
    "Sunggi",
    "Jungkweon",
    "Taemin",
    "Jonghyuk",
    "Sungho",
    "Kyunghun",
    "Jungha",
    "Youngbin",
    "Kicheol",
    "Sangjin",
    "Hyunsung",
    "Younggi",
    "Hyunwoong",
    "Jungbin",
    "Jaeha",
    "Jungyoon",
    "Segwang",
    "Youngsoo",
    "Sungyong",
    "Hadam",
    "Jungyong",
    "Youngmo",
    "Kibeom",
    "Jinmyung",
    "Byungsoo",
    "Jongsu",
    "Kyungwan",
    "Kiyong",
    "Youngjae",
    "Junhae",
    "Youil",
    "Seokhyun",
    "Donggeon",
    "Myungbin",
    "Taeyoon",
    "Jongbin",
    "Gyujin",
    "Dongyeop",
    "Jibaek",
    "Seokyeon",
    "Byungchan",
    "Myungki",
    "Seungseo",
    "Byunggi",
    "Wooyeon",
    "Sungsoo",
    "Seoktae",
    "Jinsung",
    "Byunghoon",
    "Woochang",
    "Kibeom",
    "Taeryong",
    "Taeheon",
    "Byungsik",
    "Jaehyung",
    "Sunggwon",
    "Sangmyung",
    "Dongsik",
    "Myunghyun",
    "Hawon",
    "Younggwan",
    "Junghyun",
    "Byungseok",
    "Junsik",
    "Taehoon",
    "Donghu",
    "Haram",
    "Taegun",
    "Dobin",
    "Chanhee",
    "Seongeon",
    "Seokgyu",
    "Jungwoon",
    "Mincheol",
    "Sangwon",
    "Kyungsu",
    "Youngsun",
    "Jungseok",
    "Byungyeon",
    "Suyeong",
    "Taeun",
    "Kyungwook",
    "Junghun",
    "Byungseon",
    "Doyeong",
    "Dongjin",
    "Kiyoung",
    "Sangwon",
    "Kwangmyung",
    "Dongwan",
    "Jiseop",
    "Seonghwa",
    "Hayun",
    "Mintae",
    "Taejin",
    "Seungje",
    "Junyeob",
    "Jaehong",
    "Sangyoon",
    "Youngsik",
    "Hyunmin",
    "Jinhyuk",
    "Jungseop",
    "Seokwon",
    "Taemyung",
    "Woocheol",
    "Jungbeom",
    "Taerin",
    "Jinwoong",
    "Dogyung",
    "Taesan",
    "Byungmu",
    "Subum",
    "Jungi",
    "Sangwoo",
    "Jinjung",
    "Sungju",
    "Dohyuk",
    "Byungyoung",
    "Taeyu",
    "Sanggil",
    "Dogyung",
    "Sejong",
    "Hyungyeom",
    "Jaehyuk",
    "Seungil",
    "Taeyoon",
    "Sanghyeop",
    "Wooyoung",
    "Junggeon",
    "Kikwang",
    "Taeseop",
    "Jinjae",
    "Youngwoo",
    "Jungwoo",
    "Seonguk",
    "Hyuno",
    "Byungha",
    "Gyubeom",
    "Sangho",
    "Jiyul",
    "Jungseong",
    "Taerim",
    "Sunggi",
    "Byunghoon",
    "Jinhun",
    "Jihoo",
    "Taeseung",
    "Seungje",
    "Seongeon",
    "Junggyun",
    "Seungil",
    "Junho",
    "Jungyoung",
    "Kwanghoon",
    "Taeun",
    "Dowon",
    "Woohyun",
    "Myungho",
    "Hajeong",
    "Sanghwan",
    "Jungjoo",
    "Seunggon",
    "Yoohwan",
    "Sanggyu",
    "Hajun",
    "Jinjeong",
    "Dongcheol",
    "Seungbeom",
    "Jungjae",
    "Jungwon",
    "Jaeha",
    "Donggeun",
    "Junghwan",
    "Sanggi",
    "Byungtae",
    "Sanggyu",
    "Jaeun",
    "Dohyun",
    "Myungseok",
    "Byungseon",
    "Myungcheol",
  ];

  const [audioUrls, setAudioUrls] = useState({}); // State to store audio URLs by index
  const [currentAudio, setCurrentAudio] = useState(null); // Store the current playing audio
  const contentRef = useRef(null);

  // Function to fetch voice files for the conversation
  const fetchVoiceFiles = async () => {
    try {
      const parsedScript = parseConversation(
        script[language][difficulty].script
      );
      const audioUrlsTemp = []; // Temp array to store audio URLs, leaving spaces for nulls

      let lineIndex = 2; // Start mapping from index 2

      for (let i = 0; i < parsedScript.length; i++) {
        const line = parsedScript[i];

        // Skip speaker tags and only process the text lines
        if (line.text.trim() !== "") {
          let speaker = "";
          const humanKey = `human${line.speaker}`;

          // Logic to determine speaker
          if (language === "kor") {
            if (
              boysName.some((boyName) =>
                script[language][difficulty][humanKey].includes(boyName)
              )
            ) {
              speaker = "jinho"; // Male speaker for Korean
            } else {
              speaker = "nara"; // Female speaker for Korean
            }
          } else if (language === "eng") {
            if (
              boysNameEng.some((boyNameEng) =>
                script[language][difficulty][humanKey].includes(boyNameEng)
              )
            ) {
              speaker = "matt"; // Male speaker for English
            } else {
              speaker = "djoey"; // Female speaker for English
            }
          }

          const params = {
            speaker: speaker,
            volume: 2,
            speed: -1,
            pitch: -1,
            text: line.text,
          };

          const response = await fetch(`${apiUrl}/voice`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
          });

          if (!response.ok) {
            throw new Error(
              "Failed to fetch voice for part of the conversation."
            );
          }

          const audioBlob = await response.blob();
          const audioUrl = URL.createObjectURL(audioBlob);

          // Map the audio URL to every second index, starting from 2
          audioUrlsTemp[lineIndex] = audioUrl;

          // Increment lineIndex by 2 to map to the next even index
          lineIndex += 2;
        }
      }

      setAudioUrls(audioUrlsTemp); // Store all audio URLs in state
    } catch (error) {}
  };

  useEffect(() => {
    // Fetch all audio files when the component is mounted
    fetchVoiceFiles();
    // Cleanup function
    return () => {
      // Stop the current audio if playing when the component unmounts
      stopCurrentAudio();
    };
  }, [script, language, difficulty]);

  const stopCurrentAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset audio
      setCurrentAudio(null); // Clear the current audio reference
    }
    setIsPlaying(false);
  };

  const playAudio = (audioUrl) => {
    stopCurrentAudio(); // Stop the current audio if any is playing
    const newAudio = new Audio(audioUrl);
    newAudio.play();
    setCurrentAudio(newAudio); // Set the new audio as the current audio
  };

  const handlePlayConversation = async () => {
    stopCurrentAudio(); // Stop current audio when starting sequential play
    setIsPlaying(true);
    const parsedScript = parseConversation(script[language][difficulty].script);

    for (let i = 2; i < parsedScript.length * 2 + 2; i += 2) {
      // Start from index 2 and increment by 2
      if (audioUrls[i]) {
        // Only play if there's a valid audio URL at this index
        await playAudioSequentially(audioUrls[i]); // Play each audio in sequence
      }
    }
    setIsPlaying(false);
  };

  const playAudioSequentially = (audioUrl) => {
    return new Promise((resolve) => {
      const audio = new Audio(audioUrl);
      audio.play();
      audio.onended = resolve;
      setCurrentAudio(audio); // Store the current audio being played
    });
  };

  // Parse the script into an array of objects, including both speaker and text
  const parseConversation = (scriptText) => {
    const lines = scriptText
      .replace(/\n/g, "")
      .replace(/\\n/g, "")
      .replace("<br>", "")
      .split(/=(\/[A-Z]\/)/g);

    const parsedLines = [];
    let currentSpeaker = null;

    lines.forEach((line) => {
      if (line.startsWith("/A/")) {
        currentSpeaker = "A";
      } else if (line.startsWith("/B/")) {
        currentSpeaker = "B";
      } else if (line.startsWith("/C/")) {
        currentSpeaker = "C";
      } else if (line.startsWith("/D/")) {
        currentSpeaker = "D";
      } else if (line.trim() !== "") {
        // Ensure that each line gets a unique index that corresponds to its audio
        parsedLines.push({
          speaker: currentSpeaker,
          text: line.trim(),
        });
      }
    });

    return parsedLines;
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);
  let imageUrl2;
  if (!imageUrl.startsWith("/")) {
    imageUrl2 = "/" + imageUrl;
  } else {
    imageUrl2 = imageUrl;
  }
  const accessToken = sessionStorage.getItem("accessToken");
  const manageId = useSelector((state) => state.manageId);
  const [bookmarkId, setBookmarkId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addMargin, setAddMargin] = useState(false);
  const oppositeLanguage = language === "kor" ? "eng" : "kor";
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.offsetHeight;
        const viewportHeight = window.innerHeight * 0.1;

        if (contentHeight > viewportHeight) {
          setAddMargin(true);
        } else {
          setAddMargin(false);
        }
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => window.removeEventListener("resize", checkHeight);
  }, []);

  const handleLink = () => {
    setOverlayVisible3(true);
  };

  const handleGoStudy = () => {
    stopCurrentAudio();
    if (where === "study") {
      navigate(`/${type}studylist`);
    } else if (where === "home") {
      navigate("/home");
    } else if (where === "storyboard") {
      navigate("/storyboard");
    } else {
      navigate(`/bookmark`);
    }
  };

  const ProgressBar = ({ filledSections }) => {
    const totalSections = 5;
    const gapWidth = 4; // space between sections

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    if (difficulty === "E") {
      setDifficulty("easyId");
    } else if (difficulty === "N") {
      setDifficulty("normalId");
    } else {
      setDifficulty("difficultId");
    }
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (difficulty) {
      case "easyId":
        return "E.svg";
      case "normalId":
        return "N.svg";
      case "difficultId":
        return "D.svg";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'

  useEffect(() => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const fetchData = async () => {
      const response = await fetch("https://pastellapp.com/api/bookmarks/", {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Check if itemId 5 is in the results array
        const itemFound = data.results.some(
          (item) => item.itemId === manageId && item.itemType === type2
        );

        // Update the selectedMark state based on whether the item is found
        setSelectedMark(itemFound);
      } else {
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const updateBookmark = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const postData = {
      itemId: parseInt(manageId, 10),
      itemType: type2,
    };

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
      body: JSON.stringify(postData), // Convert the data to JSON string
    });

    if (response.ok) {
      const data = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const findId = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();

      const itemFound = data.results.find(
        (item) =>
          item.itemId === parseInt(manageId, 10) && item.itemType === type2
      );

      if (itemFound) {
        return new Promise((resolve) => {
          setBookmarkId(itemFound.id);
          resolve(itemFound.id); // Resolve with the found id
        });
      } else {
        return Promise.reject("Item not found"); // Reject the promise if not found
      }
    } else {
      return Promise.reject("Failed to fetch data"); // Reject the promise if fetch fails
    }
  };

  const deleteBookmark = async (id) => {
    const response = await fetch(`https://pastellapp.com/api/bookmarks/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
    });

    if (response.ok) {
      const data = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const handleMarkAction = async () => {
    if (!selectedMark) {
      await updateBookmark();
      setSelectedMark(true);
      setShowModal(true);
    } else {
      try {
        const id = await findId(); // Wait for findId to resolve with the bookmarkId
        await deleteBookmark(id); // Pass the bookmarkId to deleteBookmark
        setSelectedMark(false);
      } catch (error) {}
    }
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  const handleGoBack = () => {
    stopCurrentAudio();
    navigate(-1);
  };
  const handleGoNext = () => {
    stopCurrentAudio();
    navigate("/studypoint", { state: { script: script, id: id } });
  };

  // Step 1: Clean up the script by removing "\n" and splitting based on speakers.

  const cleanedScript = script[language][difficulty].script
    .replace(/\n/g, "<br>")
    .replace(/\\n/g, "<br>")
    .replace("<br/>", "<br>")
    .split(/=(\/[A-Z]\/)/g);

  // Step 2: Create a helper function to format conversation.
  const formatConversation = (lines) => {
    const elements = [];
    let currentSpeaker = null;

    lines.forEach((line, index) => {
      if (line.startsWith("/")) {
        currentSpeaker = line; // Store the current speaker (either /A/ or /B/)
      } else if (line.trim() !== "") {
        // Determine speaker label based on the current speaker
        const speakerLabel =
          currentSpeaker === "/A/"
            ? script[language][difficulty].humanA + ": "
            : currentSpeaker === "/B/"
            ? script[language][difficulty].humanB + ": "
            : currentSpeaker === "/C/"
            ? script[language][difficulty].humanC + ": "
            : script[language][difficulty].humanD + ": ";

        const alignment =
          currentSpeaker === "/A/"
            ? "self-end justify-end" // Right-aligned bubble for humanB
            : "self-start justify-start"; // Left-aligned bubble for humanA

        const bubbleColor =
          currentSpeaker === "/A/"
            ? "bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-[18px] rounded-br-0" // Bubble style for humanA
            : currentSpeaker === "/B/"
            ? "bg-[#fff] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0" // Bubble style for humanB
            : currentSpeaker === "/C/"
            ? "bg-[#B7FFC3] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-[18px] rounded-br-0" // Bubble style for humanC
            : "bg-[#98EDFF] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-0 rounded-br-[18px]"; // Bubble style for humanD

        // Attach click event to play the specific audio for this line
        const handlePlayLineAudio = () => {
          const audioUrl = audioUrls[index]; // Get the corresponding audio URL
          if (audioUrl) {
            playAudio(audioUrl); // Play the specific audio for this line
          }
        };

        elements.push(
          <div
            key={index}
            className={`flex ${alignment} w-full py-[10px] px-[16px]`} // w-full ensures the bubble takes the full available width for alignment
            onClick={isPlaying ? stopCurrentAudio : handlePlayLineAudio}
          >
            <div
              className={`text-[14px] leading-[160%] font-sans text-[#000] break-words ${bubbleColor}`}
              style={{
                padding: "8px",
                maxWidth: "75%", // Bubble can grow up to 75% of the container
                wordWrap: "break-word",
                display: "inline-block", // Prevents the bubble from expanding unnecessarily
              }}
              dangerouslySetInnerHTML={{
                __html: `<strong>${speakerLabel}</strong> ${line.trim()}`,
              }}
            >
              {/* <strong>{speakerLabel}</strong> */}
              {/* {line.trim()} */}
            </div>
          </div>
        );
      }
    });
    return elements;
  };

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(""); // State to store the background image URL
  const [isOverlayVisible3, setOverlayVisible3] = useState(false);
  const overlayRef3 = useRef(null); // Ref for the overlay

  // Function to check if the image exists
  const checkImageExists = (url, callback) => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    img.src = url;
  };

  useEffect(() => {
    const url = `https://pastellapp.com/api/upload${imageUrl2}`;

    checkImageExists(url, (exists) => {
      if (exists) {
        setBackgroundImageUrl(url);
      } else {
        const randomBgImage = Math.random() < 0.5 ? bgImage1 : bgImage2;
        setBackgroundImageUrl(randomBgImage);
      }
    });
  }, [imageUrl2]); // Only re-run the effect if `imageUrl` changes

  return (
    <div
      id="swipe-container"
      className="relative flex flex-col bg-[#fff] "
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1) 100%), url('${backgroundImageUrl}')`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh", // Ensure full viewport height
        width: "100%", // Ensure full viewport width
        overflow: "hidden auto", // Prevent scrolling
        scrollBehavior: "smooth", // Add smooth scroll behavior
      }}
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="absolute w-full h-full bg-black opacity-0 z-10"></div>
      {/* Header */}
      <div className="relative flex items-center px-[16px] h-[50px]">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] p-[10px] z-40">
          <img
            width="60"
            height="60"
            src="x.png"
            alt="icon"
            onClick={toggleModal}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-40 text-white font-sans text-center">
          {translations.so1}
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "78%" }}
        >
          <img
            width="40"
            height="40"
            src="link.png"
            alt="icon"
            onClick={handleLink}
          />
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "88%" }}
        >
          <img
            width="45"
            height="45"
            src="ddd.png"
            alt="icon"
            onClick={toggleOverlay}
          />
        </div>
      </div>
      {/* Progress Bar */}
      <ProgressBar filledSections={1} />
      {/* Content */}
      <div className="flex justify-end mr-[10px] mt-[10px] z-50">
        <LanguageSwitcher2></LanguageSwitcher2>
      </div>
      <div
        ref={contentRef}
        className="relative w-full flex flex-1 flex-col justify-start min-h-[80%] z-40" // No unnecessary height or flex-grow
      >
        {/* Content area */}
        <div className="w-full flex flex-grow flex-col items-start justify-start">
          {formatConversation(cleanedScript)}
          <div className="h-[20px]"></div>{" "}
          {/* Extra spacing after the conversation */}
        </div>

        {/* Footer */}
        <div className="w-full z-50 ml-[5px] ">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center gap-[24px]">
              <div
                className="relative w-[30px] h-[30px] shrink-0"
                onClick={handleGoBack}
              >
                <img className="relative" width="18" height="18" src="fb.png" />
              </div>
              <div
                className="relative w-[50px] h-[70px] shrink-0 flex"
                onClick={toggleOverlay2}
              >
                <div className="flex flex-col items-center gap-[8px]">
                  <div className="relative w-[50px] h-[50px] shrink-0">
                    <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                    <img
                      className="absolute -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2"
                      width="24"
                      src={getImageSrc()}
                    ></img>
                  </div>
                  <div className="text-[12px] text-[#fff] text-center">
                    {translations.DIFFICULTY}
                  </div>
                </div>
              </div>
              <div
                className="w-[100px] h-[100px] shrink-0 flex"
                onClick={isPlaying ? stopCurrentAudio : handlePlayConversation}
              >
                <img
                  className="relative"
                  width="100"
                  height="100"
                  src={isPlaying ? "pause.svg" : "play.png"}
                />
              </div>
              <div
                className="relative w-[50px] h-[70px] shrink-0 flex"
                onClick={handleMarkAction}
              >
                <div className="flex flex-col items-center gap-[8px]">
                  <div className="relative w-[50px] h-[50px] shrink-0">
                    <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                    <img
                      className="absolute  left-1/2 -translate-x-1/2  -translate-y-1/2 top-1/2"
                      width="13"
                      height="24"
                      src={getMarkImage()}
                    />
                  </div>
                  {/* <div className="text-[12px] text-[#fff] text-center">
                    {translations.BOOKMARK}
                  </div> */}
                  <div className="absolute left-1/2 top-[57px] w-[52px] h-[12px] text-[12px] leading-[100%] font-sans text-[#fff] text-center transform -translate-x-1/2">
                    {translations.BOOKMARK}
                  </div>
                </div>
              </div>
              <div
                className="relative w-[30px] h-[30px] shrink-0"
                onClick={handleGoNext}
              >
                <img className="relative" width="18" height="18" src="ff.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => {
              stopCurrentAudio();
              navigate("/langsetting");
            }}
          >
            {translations.LANGUAGE_SETTINGS}
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          {/* Text Container 2 */}
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => {
              stopCurrentAudio();
              navigate("/error-action");
            }}
          >
            {translations.REPORT_ERROR}
          </div>
        </div>
      </div>
      {/* Overlay2 */}
      <div
        ref={overlayRef2}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible2
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay2 */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            난이도
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("E")}
          >
            EASY
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("N")}
          >
            NORMAL
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("D")}
          >
            DIFFICULT
          </div>
        </div>
      </div>

      {/* Overlay */}
      <div
        ref={overlayRef3}
        className={`fixed top-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible3
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "100%",
          zIndex: 100,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
      >
        {/* App Bar with Close Button */}
        <div className="flex items-center justify-between p-4 bg-[#f5f5f5]">
          <button
            onClick={() => setOverlayVisible3(false)} // Assumes setIsOverlayVisible is a function to toggle visibility
            className="text-[#3677f6] text-[16px] font-bold"
          >
            Close
          </button>
        </div>

        {/* Display Google in an iframe */}
        <iframe
          src="https://pastellapp.com/appinfo"
          style={{
            width: "100%",
            height: "calc(100% - 60px)", // Adjust iframe height to account for the app bar
            border: "none",
          }}
          title="ezBrainSoft"
        ></iframe>
      </div>

      <StudyQuitModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onAction1={handleModalAction1}
        onAction2={handleGoStudy}
      />
      <BookmarkModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Conversation;
