import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import Modal from "./Modal";
import { generateChatResponse } from "../utils/openai";
import RingLoader from "react-spinners/RingLoader";

const AiChat = () => {
  const { translations } = useContext(LanguageContext);
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [apiItems, setApiItems] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [completionMessage, setCompletionMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { apiItems: apiItemsFromLocation } = location.state || {};

  useEffect(() => {
    if (apiItemsFromLocation) {
      generateInitialPrompt(apiItemsFromLocation);
    }
  }, [apiItemsFromLocation]);

  const generateInitialPrompt = async (items) => {
    const prompt = generatePrompt(items, 0, true);

    try {
      const response = await generateChatResponse(prompt);

      setChatMessages([{ role: "assistant", content: response }]);
      setApiItems(items);
    } catch (error) {
      // 오류 처리 로직 추가 (예: 사용자에게 오류 메시지 표시)
    } finally {
      setIsLoading(false);
    }
  };

  const generatePrompt = (items, index = 0, isInitial = false) => {
    const language = items[0].title.match(/^[a-zA-Z\s]*$/)
      ? "English"
      : "Korean";

    let prompt = "";

    if (language === "English") {
      prompt = `You are an expert AI language tutor for English. Help the user learn vocabulary without providing answers upfront. Follow these instructions:

1. Ask questions using only the word "${items[index].title}". Don't provide answers.
2. After the question, provide only a hint in this format: "tip: (${items[index].title}: ${items[index].content})"
3. Wait for the user's response.
4. When the user responds, provide feedback. Praise correct usage and suggest corrections for errors.
5. Don't automatically move to the next word. Wait for additional instructions.`;
    } else {
      prompt = `당신은 한국어 전문 AI 언어 튜터입니다. 사용자가 어휘를 학습하도록 돕되, 답변을 미리 제공하지 마세요. 다음 지침을 따르세요:

1. "${items[index].title}" 단어를 사용하는 질문만 하세요. 답변은 하지 마세요.
2. 질문 후 다음 형식으로 힌트만 제공하세요: "힌트: (${items[index].title}: ${items[index].content})"
3. 사용자의 응답을 기다리세요.
4. 사용자가 응답하면 피드백을 제공하세요. 올바르게 사용했다면 칭찬하고, 오류가 있다면 수정 제안을 해주세요.
5. 다음 단어로 자동으로 넘어가지 마세요. 추가 지시를 기다리세요.`;
    }

    if (isInitial) {
      prompt += `\n\n${
        language === "English" ? "Vocabulary items:" : "어휘 항목:"
      }
${items.map((item) => `${item.title}: ${item.content}`).join("\n")}`;
    }

    return prompt;
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;

    const newUserMessage = { role: "user", content: inputMessage };
    setChatMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setInputMessage("");

    try {
      const response = await generateChatResponse(inputMessage);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: response },
      ]);

      // Move to the next word
      if (currentWordIndex < apiItems.length - 1) {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
        const nextPrompt = generatePrompt(apiItems, currentWordIndex + 1);
        const nextResponse = await generateChatResponse(nextPrompt);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: nextResponse },
        ]);
      } else {
        // All words have been practiced
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: completionMessage },
        ]);
      }
    } catch (error) {}
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoAi = () => {
    navigate("/ai");
  };

  useEffect(() => {
    if (apiItems && apiItems.length > 0) {
      const initialPrompt = generatePrompt(apiItems, 0);
      generateChatResponse(initialPrompt)
        .then((response) => {
          setChatMessages([{ role: "assistant", content: response }]);
        })
        .catch((error) => {});
    }
  }, [apiItems]);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <RingLoader color="#2986dd" size={75} />
      </div>
    );
  }

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6]">
        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] px-[10px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
                onClick={toggleModal}
              >
                <img width="36" height="36" src="xbutton.png"></img>
              </div>
              <div className="absolute left-1/2 transform -translate-x-1/2 text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap">
                Chat
              </div>
              <div className="flex flex-row items-center justify-end gap-[8px]">
                <div
                  className="h-[36px] flex flex-row items-center justify-center"
                  onClick={() => navigate("/error-action")}
                >
                  <div className="flex flex-row items-center justify-center py-[8px] px-[14px] border-[2px] border-solid border-[#000] dark:border-[#fff] rounded-[59px]">
                    <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap dark:text-[#fff]">
                      {translations.aichat1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        {/* chat */}
        <div
          ref={chatContainerRef}
          className="absolute left-[2%] top-[106px] w-[96%] flex flex-col items-start justify-start gap-[20px] overflow-y-auto max-h-[calc(100vh-200px)]"
        >
          {chatMessages.map((message, index) => (
            <div
              key={index}
              className={`flex flex-row items-center justify-center py-[10px] px-[16px] ${
                message.role === "assistant"
                  ? "bg-[#c5d0dd] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0"
                  : "bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-[18px] self-end"
              }`}
            >
              <div className="text-[14px] leading-[160%] font-['Pretendard'] text-[#000] whitespace-pre-wrap">
                {message.content}
              </div>
            </div>
          ))}
        </div>
        <div className="absolute left-0 bottom-0 w-[100%] flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-row items-center justify-center gap-[10px] p-[10px] bg-[#fff]">
            <div className="w-[82.5%] h-[40px] shrink-0 flex flex-row items-center justify-start py-[18px] px-[14px] border-[1px] border-solid border-[#e2e8ef] rounded-[12px]">
              <input
                className="text-[16px] leading-[100%] font-['Pretendard'] font-medium text-[#000] whitespace-nowrap focus:outline-none w-full"
                placeholder={translations.aichat2}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
              />
            </div>
            <img
              width="32"
              height="32"
              src="icon117_1764.png"
              onClick={handleSendMessage}
              style={{ cursor: "pointer" }}
            ></img>
          </div>
        </div>
        <Modal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onAction1={handleModalAction1}
          onAction2={handleGoAi}
        />
      </div>
    </div>
  );
};

export default AiChat;
