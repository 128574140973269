import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import { IoIosArrowBack } from "react-icons/io";
import { CgSortAz } from "react-icons/cg";
import kor_eng from "../data/select_kor_eng.json";
import BeatLoader from "react-spinners/BeatLoader";
import StoryBoardModal from "./StoryBoardModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StoryBoardCreate = () => {
  const dispatch = useDispatch();
  const [selectedGender, setSelectedGender] = useState(
    sessionStorage.getItem("bookmarkFilter") ?? null
  );
  const [selectedItems, setSelectedItems] = useState([]); // Track selected items
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]); // Initialize state for storing results
  const [data3, setData3] = useState({}); // Initialize state for storing results
  const [filteredData, setFilteredData] = useState([]); // Filtered data to display
  const setwhere = useSelector((state) => state.setWhere);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  const { translations, language } = useContext(LanguageContext);
  const oppositeLanguage = language === "kor" ? "eng" : "kor";

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedData, setSortedData] = useState([]);

  const [visibleItems, setVisibleItems] = useState(10); // Start by showing 10 items
  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10); // Load 10 more items on each click
  };

  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleGoBack = () => {
    sessionStorage.removeItem("bookmarkFilter");
    if (sessionStorage.getItem("whereBookmark") === "home") {
      sessionStorage.removeItem("whereBookmark");
      navigate("/home");
    } else {
      sessionStorage.removeItem("whereBookmark");
      navigate("/study");
    }
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  useEffect(() => {
    if (selectedGender === "Psychology") {
      sessionStorage.setItem("bookmarkFilter", "Psychology");
      const psychologyItems = sortedData.filter((item) => item.psychologys);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "MBTI") {
      sessionStorage.setItem("bookmarkFilter", "MBTI");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.mbti
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Persona") {
      sessionStorage.setItem("bookmarkFilter", "Persona");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.age
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Situation") {
      sessionStorage.setItem("bookmarkFilter", "Situation");
      const psychologyItems = sortedData.filter((item) => item.situations);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Expression") {
      sessionStorage.setItem("bookmarkFilter", "Expression");
      const psychologyItems = sortedData.filter((item) => item.expressions);
      setFilteredData(psychologyItems);
    } else {
      setFilteredData(sortedData);
    }
  }, [sortedData]);

  const handleComplete = () => {
    toggleOverlay();

    if (selectedGender === "Psychology") {
      sessionStorage.setItem("bookmarkFilter", "Psychology");
      const psychologyItems = sortedData.filter((item) => item.psychologys);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "MBTI") {
      sessionStorage.setItem("bookmarkFilter", "MBTI");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.mbti
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Persona") {
      sessionStorage.setItem("bookmarkFilter", "Persona");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.age
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Situation") {
      sessionStorage.setItem("bookmarkFilter", "Situation");
      const psychologyItems = sortedData.filter((item) => item.situations);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Expression") {
      sessionStorage.setItem("bookmarkFilter", "Expression");
      const psychologyItems = sortedData.filter((item) => item.expressions);
      setFilteredData(psychologyItems);
    } else {
      sessionStorage.removeItem("bookmarkFilter");
      setFilteredData(sortedData);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/bookmarks?page=1&limit=1000`, {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const result = await response.json();
        setData(result.results);
      } else {
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const fetchItemTitles = async () => {
      const results = []; // Temporary array to accumulate results

      // Step 1: Iterate over the results array and extract both itemId and itemType
      for (const item of data) {
        const itemId = item.itemId;
        const type = item.itemType;
        let url;

        if (type === "mbti" || type === "persona") {
          url = `${apiUrl}/customize/manage/${itemId}`;
        } else if (type === "expression") {
          url = `${apiUrl}/expressions/manage/${itemId}`;
        } else {
          url = `${apiUrl}/${type}/manage/${itemId}`;
        }

        try {
          // Step 2: Use both itemType and itemId in the API URL
          const response = await fetch(url);

          if (response.ok) {
            const resultData = await response.json();
            // Step 3: Accumulate the resultData in the results array
            results.push(resultData);
          } else {
            console.error(
              `Error fetching item with ID ${itemId}:`,
              response.status
            );
          }
        } catch (error) {}
      }

      // Step 4: Once all items are fetched, update the data2 state
      setData2(results);
    };

    if (data.length > 0) {
      fetchItemTitles();
    }
  }, [data, apiUrl]); // Dep

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData3(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if translations have the item with the given id
    if (node.translations) {
      const foundTranslation = node.translations.find(
        (translation) => translation.id === id
      );
      if (foundTranslation) return foundTranslation;
    }

    // Recursively search in children
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };

  const sortData = useCallback(
    (dataToSort) => {
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle = a.title || "";
          const bTitle = b.title || "";
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? parseInt(a.averageRating ?? -1, 10) -
                parseInt(b.averageRating ?? -1, 10)
            : parseInt(b.averageRating ?? -1, 10) -
                parseInt(a.averageRating ?? -1, 10);
        });
      }

      return sortedData;
    },
    [sortConfig]
  );

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    const sortedData = sortData(data2);

    setSortedData(sortedData);
    setFilteredData(sortedData);
  }, [data2, sortConfig, sortData]);

  const [selectKorEng, setSelectKorEng] = useState({});

  const formatEnglishCategory = (category) => {
    return category
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getCategoryName = (category) => {
    if (selectKorEng.expression) {
      const entries = Object.entries(selectKorEng.expression);
      for (const [kor, eng] of entries) {
        if (eng === category) {
          return language === "kor" ? kor : formatEnglishCategory(eng);
        }
      }
    }
    return language === "kor" ? category : formatEnglishCategory(category);
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const alreadySelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );

      if (alreadySelected) {
        const updatedItems = prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
        return updatedItems;
      }

      if (prevSelectedItems.length >= 3) {
        const toastId = "tag-error";
        if (!toast.isActive(toastId)) {
          // Show error toast if email is invalid
          toast.error("최대 3개까지 선택 가능합니다.", {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId, // Assign the unique toastId
          });
        }
        return prevSelectedItems;
      }

      const updatedItems = [...prevSelectedItems, item];
      return updatedItems;
    });
  };

  // 로컬스토리지 저장
  const getNextAvailableKey = () => {
    let i = 1;
    while (localStorage.getItem(`storyboard${i}`)) {
      i++;
    }
    return `storyboard${i}`;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // Function to handle button click and confirm storing data in localStorage
  const handleStoreItems = () => {
    const key = getNextAvailableKey(); // Find the next available key (storyboard1, storyboard2, etc.)
    localStorage.setItem(key, JSON.stringify(selectedItems));
    setIsModalVisible(true);
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6] dark:bg-[#121418]">
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "80%",
            zIndex: 100,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          {/* Title of the overlay */}
          <div className="p-4">
            <h2 className="text-lg font-bold">필터 설정</h2>
          </div>
          {/* Content of the overlay */}
          <div className="flex flex-wrap gap-[6px] justify-center">
            {typeList.map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selectedGender === gender
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("gender", gender)}
                style={{ flexBasis: "calc(33.333% - 6px)" }}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className="fixed bottom-0 w-full">
            <div className="flex-none pb-[20px] px-[16px]">
              <button
                className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
                onClick={handleComplete}
              >
                설정 완료
              </button>
            </div>
          </div>
        </div>

        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
                onClick={handleGoBack}
              >
                {/* <img width="8" height="16" src="back.png"></img> */}
                <IoIosArrowBack
                  color={darkMode ? "white" : "black"}
                  size={30}
                />
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                {translations.STORYBOARD_CREATE}
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0">
                  <div
                    className="absolute left-0 right-0 top-0 bottom-0"
                    onClick={toggleOverlay}
                  >
                    <CgSortAz
                      color={darkMode ? "white" : "black"}
                      size={30}
                    ></CgSortAz>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          {/* Sorting Options */}
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "latest"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("latest")}
              >
                {translations.ORDER_DATE}{" "}
                {sortConfig.key === "latest" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "alphabetical"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("alphabetical")}
              >
                {translations.ORDER_AL}{" "}
                {sortConfig.key === "alphabetical" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "rating"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("rating")}
              >
                {translations.ORDER_RA}{" "}
                {sortConfig.key === "rating" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="w-full flex flex-col items-start bg-[#fff] dark:bg-[#31333e] overflow-y-auto">
            {/* 카드 */}
            {filteredData.length === 0 ? (
              <div className="flex justify-center items-center w-full h-full">
                <BeatLoader
                  color="#3677f6"
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              filteredData.slice(0, visibleItems).map((item, index) => {
                // Check if `customizes` is defined and has at least 2 elements
                if (
                  (item.customizes && item.customizes.length > 1) ||
                  item.situations ||
                  item.psychologys
                ) {
                  const isSelected = selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id
                  );
                  return (
                    <div
                      key={index}
                      className={`w-full flex flex-row items-start justify-between py-[14px] px-[16px] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0 ${
                        isSelected ? "bg-[#e2e8ef]" : "bg-[#fff]"
                      }`}
                      onClick={() => handleSelectItem(item)}
                    >
                      {/* Titles */}
                      <div className="flex flex-col gap-[17px]">
                        <div className="flex flex-col gap-[12px]">
                          <div className="flex items-center">
                            {/* English title */}
                            <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-all">
                              {item.customizes
                                ? item.customizes.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title
                                : item.situations
                                ? item.situations.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title
                                : item.psychologys.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title}{" "}
                              {/* English title */}
                            </div>
                          </div>
                          {/* Korean title */}
                          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-all">
                            {item.customizes
                              ? item.customizes.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title
                              : item.situations
                              ? item.situations.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title
                              : item.psychologys.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title}{" "}
                            {/* Korean title */}
                          </div>
                        </div>

                        {/* Tags */}
                        <div className="flex items-center gap-[3px]">
                          {item.tag &&
                            item.tag.map((tag, tagIndex) => (
                              <div
                                key={tagIndex}
                                className="flex items-center gap-[3px]"
                              >
                                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                  {data
                                    ? findItemById(tag.id, data3)?.name
                                    : "Loading..."}
                                  {/* Display the tag id */}
                                </div>
                                {tagIndex < item.tag.length - 1 && (
                                  <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* Rating */}
                      <div className="flex items-center gap-[4px]">
                        <img
                          className={darkMode ? "filter: invert" : ""}
                          width="15"
                          height="15"
                          src={`star.png`}
                          alt="rating star"
                        />
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap">
                          {item.averageRating.toFixed(1)}{" "}
                          {/* Display the rating */}
                        </div>
                      </div>
                    </div>
                  );
                }
                if (item.expressions && item.expressions.length > 1) {
                  const isSelected = selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id
                  );
                  return (
                    <div
                      key={index}
                      className={`w-full flex flex-row items-start justify-between py-[14px] px-[16px] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0 ${
                        isSelected ? "bg-[#e2e8ef]" : "bg-[#fff]"
                      }`}
                      onClick={() => handleSelectItem(item)}
                    >
                      {/* Titles */}
                      <div className="flex flex-col gap-[17px]">
                        <div className="flex flex-col gap-[12px]">
                          <div className="flex items-center">
                            {/* English title */}
                            <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-all">
                              {
                                item.expressions.find(
                                  (customize) => customize.language === "kor"
                                )?.expressive_language
                              }{" "}
                              {/* English title */}
                            </div>
                          </div>
                          {/* Korean title */}
                          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-all">
                            {
                              item.expressions.find(
                                (customize) => customize.language === "eng"
                              )?.expressive_language
                            }{" "}
                            {/* Korean title */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            )}

            {/* Load More Button */}
            {visibleItems < data2.length && (
              <div
                className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e]
 border border-[#e9ecef] dark:border-[#000] w-[100%]"
              >
                <button
                  onClick={loadMoreItems}
                  className="px-4 py-2 bg-[#007bff] text-white rounded"
                >
                  더보기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "80%",
          zIndex: 100,
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
        }}
      >
        {/* Title of the overlay */}
        <div className="p-4">
          <h2 className="text-lg font-bold">{translations.filter1}</h2>
        </div>
        {/* Content of the overlay */}
        <div className="flex flex-wrap gap-[6px] justify-center">
          {typeList.map((gender) => (
            <div
              key={gender}
              className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                selectedGender === gender
                  ? "border-[#3677f6] text-[#3677f6]"
                  : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
              }`}
              onClick={() => handleSelect("gender", gender)}
              style={{ flexBasis: "calc(33.333% - 6px)" }}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                {getCategoryName(gender)}
              </div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="fixed bottom-0 w-full">
          <div className="flex-none pb-[20px] px-[16px]">
            <button
              className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
              onClick={handleComplete}
            >
              {translations.filter2}
            </button>
          </div>
        </div>
      </div>
      {/* Conditionally show "Store in localStorage" button */}
      {selectedItems.length > 0 && (
        <footer className="fixed w-full bottom-0 py-4 px-4">
          <button
            className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
            onClick={handleStoreItems}
          >
            {translations.sb1} ({selectedItems.length})
          </button>
        </footer>
      )}
      <ToastContainer />
      {isModalVisible && <StoryBoardModal onClose={handleCloseModal} />}
    </div>
  );
};

export default StoryBoardCreate;
