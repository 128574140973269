import React, { useState, useEffect } from 'react';
import { 
  createExpressionManage, 
  fetchExpressionManage, 
  updateExpressionManage, 
  createExpression, 
  updateExpression 
} from '../../api/admin/expression';
import ExpressionManageSection from '../../components/admin/ExpressionManageSection'; 
import ExpressionForm from '../../components/admin/ExpressionForm';
import Navigation from '../../components/admin/Navigation';
import ExpressionSaveButton from '../../components/admin/ExpressionSaveButton';
import { useParams, useNavigate } from 'react-router-dom';
import plusIcon from '../../assets/admin/icons/plus.png';

const ExpressionManage = ({ mode = 'create' }) => {
  const navigate = useNavigate();
  const { managerId } = useParams();

  const [manageData, setManageData] = useState({
    title: '',
    category: '',
    tag: [],
    academic_english: false,
    memo: '',
    link: '',
    used: false,
    serialnumber: '',
  });

  const [expression, setExpression] = useState({
    kor: {
      language: 'kor',
      expressive_language: '',
      expressive_language_mean: '',
      example_sentence: '',
      example_sentence_mean: '',
      synonyms: '',
      synonyms_mean: '',
      antonyms: '',
      antonyms_mean: ''
    },
    eng: {
      language: 'eng',
      expressive_language: '',
      expressive_language_mean: '',
      example_sentence: '',
      example_sentence_mean: '',
      synonyms: '',
      synonyms_mean: '',
      antonyms: '',
      antonyms_mean: ''
    }
  });

  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [visibleLanguages, setVisibleLanguages] = useState(['kor', 'eng']);

  const reverseLanguageMap = {
    eng: 'eng', // 변경하지 않고 그대로 사용
    kor: 'kor',
    jpn: 'jpn'
  };

  const onTagsChange = (tags) => {
    console.log("Received tags:", tags);
    setManageData(prev => ({ ...prev, tag: tags }));
    console.log("setManageData:", manageData);
  };

  const handleManageChange = (updatedData) => {
    setManageData(prev => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleLinkChange = (newLink) => {
    handleManageChange({ link: newLink });
  };

  const handleLanguageChange = (lang) => {
    const selectedExpression = expression[lang] || {
        language: lang,
        expressive_language: '',
        expressive_language_mean: '',
        example_sentence: '',
        example_sentence_mean: '',
        synonyms: '',
        synonyms_mean: '',
        antonyms: '',
        antonyms_mean: ''
    };
    setExpression(prev => ({
        ...prev,
        [lang]: selectedExpression
    }));
  };

  const handleAddLanguage = () => {
    if (visibleLanguages.length < 3) {
      const newLanguage = 'jpn';
      setVisibleLanguages(prev => [...prev, newLanguage]);
      
      setExpression(prev => ({
        ...prev,
        [newLanguage]: {
          language: newLanguage,
          expressive_language: '',
          expressive_language_mean: '',
          example_sentence: '',
          example_sentence_mean: '',
          synonyms: '',
          synonyms_mean: '',
          antonyms: '',
          antonyms_mean: ''
        }
      }));
      
      console.log(`Added new language: ${newLanguage}`);
      console.log('Updated visible languages:', [...visibleLanguages, newLanguage]);
    }
  };

  const handleLanguageReset = (language) => {
    setExpression(prevState => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        expressive_language: '',
        expressive_language_mean: '',
        example_sentence: '',
        example_sentence_mean: '',
        synonyms: '',
        synonyms_mean: '',
        antonyms: '',
        antonyms_mean: '',
      }
    }));
  };

  useEffect(() => {
    const loadCategoriesAndLanguages = async () => {
      try {
        const response = await fetch('/expressionData.json');
        const data = await response.json();
  
        setCategories(data.EXPRESSION || []);
        setLanguages(data.LANGUAGE || []);
  
      } catch (error) {
        console.error('Failed to load categories and languages:', error);
      }
    };
  
    const loadManageData = async () => {
      if (mode === 'edit' && managerId) {
        try {
          const manageResponse = await fetchExpressionManage(managerId);
          const formattedTags = manageResponse.tag.map(tagItem => tagItem.id);
    
          setManageData({
            ...manageResponse,
            tag: formattedTags,
          });
          console.log('Get manageData:', {
            ...manageResponse,
            tag: formattedTags,
          });
    
          const expressions = manageResponse.expressions;
          const updatedExpression = {};
          const updatedVisibleLanguages = [];
    
          if (expressions && expressions.length > 0) {
            for (const exp of expressions) {
              const langCode = reverseLanguageMap[exp.language];
              if (langCode) {
                updatedExpression[langCode] = {
                  ...exp,
                  expressionId: exp.id,
                };
                updatedVisibleLanguages.push(langCode);
              } else {
                console.warn(`Language code not found for: ${exp.language}`);
              }
            }
          }
    
          setExpression(updatedExpression);
          setVisibleLanguages(updatedVisibleLanguages);
          console.log('Get expressionResponse:', updatedExpression);
    
        } catch (error) {
          console.error('데이터를 가져오는 중 오류 발생:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    loadCategoriesAndLanguages();
    loadManageData();
  }, [mode, managerId]);

  const handleSave = async () => {
    console.log('Saving manageData:', manageData);
    console.log('Saving expression:', expression);
    
    try {
      if (mode === 'create') {
        const createdManage = await createExpressionManage(manageData);

        for (const lang in expression) {
          await createExpression({
            ...expression[lang],
            managerId: createdManage.id,
            language: lang
          });
        }

        console.log('Created manage:', createdManage);
        alert('스크립트 저장이 완료되었습니다.');
      } else if (mode === 'edit') {
        await updateExpressionManage(managerId, manageData);

        for (const lang in expression) {
          const expData = expression[lang];
          if (expData.id) {
            await updateExpression(expData.id, expData);
          } else {
            await createExpression({
              ...expData,
              managerId,
              language: lang
            });
          }
        }

        console.log('Updated manage:', manageData);
        console.log('Updated expression:', expression);
        alert('스크립트 수정이 완료되었습니다.');
      }
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };

  return (
  <div style={{backgroundColor: '#FAF8F6'}}>
    <Navigation />
    <div 
      className="min-h-screen"
      style={{ 
        padding: '30px 30px 30px 30px',
      }}
    >
      <ExpressionManageSection
          mode={mode}
          manageData={manageData}
          onManageChange={handleManageChange}
          setManageData={setManageData}
          onLinkChange={handleLinkChange}
          categories={categories}
          onTagsChange={onTagsChange}
      />
      {visibleLanguages.map(lang => (
        <ExpressionForm
          key={lang}
          expression={expression[lang] || {}}  // 기본값 제공
          onExpressionChange={newData =>
            setExpression(prev => ({
              ...prev,
              [lang]: {
                ...prev[lang],
                ...newData
              }
            }))
          }
          languages={languages}
          selectedLanguage={lang}
          onLanguageChange={handleLanguageChange}
          onLanguageReset={() => handleLanguageReset(lang)}
        />
      ))}
      {visibleLanguages.length < 3 && (
        <button
          onClick={handleAddLanguage}
          className="mt-4 bg-white rounded-lg shadow-md w-full cursor-pointer h-[70px] flex items-center justify-between px-6 text-[#383C4B] font-bold font-pretendard"
        >
          <span className="text-lg">언어 추가하기</span>
          <img src={plusIcon} alt="Add" className="h-7 w-7" />
        </button>
      )}
      <ExpressionSaveButton 
        onSave={handleSave} 
        mode={mode} 
        navigate={navigate}
      />
    </div>
  </div>
  );
};

export default ExpressionManage;