import React, { useState, useEffect } from 'react';
import Navigation from '../../components/admin/Navigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import axiosInstance from '../../utils/admin/axiosConfig';
import { ChevronDownIcon } from '@heroicons/react/solid';
import API_BASE_URL from '../../config';
import axios from 'axios';

const HomeDashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState('day');
  const [newMembers, setNewMembers] = useState([]);
  const [stats, setStats] = useState({
    dailySales: 0,
    dailyVisits: 0,
    dailySignups: 0,
    totalMembers: 0
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchStats();
    fetchNewMembers();
  }, [selectedPeriod]);

  const fetchStats = async () => {
    try {
      const [userStats, visitStats, paymentStats] = await Promise.all([
        axiosInstance.get(`${API_BASE_URL}/auth/user-stats`),
        axiosInstance.get(`${API_BASE_URL}/auth/today-visit-count`),
        axios.get(`${API_BASE_URL}/payment/today-total-amount`)
      ]);
      setStats({
        dailySales: paymentStats.data.totalAmount,
        dailyVisits: visitStats.data.todayVisitCount,
        dailySignups: userStats.data.newUsersToday,
        totalMembers: userStats.data.totalUsers
      });
    } catch (error) {
      console.error('통계 데이터 불러오기 실패:', error);
    }
  };

  const fetchNewMembers = async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/auth/users-by-date?range=${selectedPeriod}`);
      setNewMembers(response.data);
    } catch (error) {
      console.error('신규 회원 데이터 불러오기 실패:', error);
    }
  };

  const updateDateRange = (period) => {
    const end = new Date();
    const start = new Date();
    switch (period) {
      case 'day':
        start.setDate(end.getDate() - 1);
        break;
      case 'week':
        start.setDate(end.getDate() - 7);
        break;
      case 'month':
        start.setMonth(end.getMonth() - 1);
        break;
      case 'year':
        start.setFullYear(end.getFullYear() - 1);
        break;
    }
    setStartDate(start);
    setEndDate(end);
    setSelectedPeriod(period);
  };

  const periodOptions = [
    { value: 'day', label: '오늘' },
    { value: 'week', label: '이번주' },
    { value: 'month', label: '이번달' },
    { value: 'year', label: '올해' },
  ];

  const selectedDropdownStyle = {
    height: '36px',
    padding: '4px 14px',
    borderRadius: '12px',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100px',
  };

  console.log(localStorage.getItem('accessToken'));
  console.log(stats);
  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>

        {/* 통계 정보 */}
        <div className="grid grid-cols-4 gap-4 mb-8">
          <StatCard title="당일 매출 금액(원)" value={stats.dailySales} />
          <StatCard title="당일 누적 방문수(회)" value={stats.dailyVisits} />
          <StatCard title="당일 신규 가입수(명)" value={stats.dailySignups} />
          <StatCard title="전체 누적 회원수(명)" value={stats.totalMembers} />
        </div>

        {/* 기간 선택 */}
        <div className="mt-6 mb-8 flex items-center">
          <div className="relative">
            <div
              className="bg-white"
              style={selectedDropdownStyle}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span>{periodOptions.find(option => option.value === selectedPeriod).label}</span>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            </div>
            {isDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                {periodOptions.map((option) => (
                  <div
                    key={option.value}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      updateDateRange(option.value);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* 신규회원 목록 */}
        <div className="bg-white rounded-lg shadow-md mt-8">
          <div className="flex justify-between items-center p-5">
            <h2 className="text-xl font-bold text-[#0E111F]">신규회원 목록</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-[#E2E8EF]">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No
                  </th>
                  {['구분', '이름', '휴대폰번호', '닉네임', 'Native', 'Target', '유료회원', '추천인', '가입일시', '상태'].map((header) => (
                    <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {newMembers.map((member, index) => (
                  <tr key={member.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.loginType || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.username || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.phoneNumber || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.nickname || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.nativeLanguage || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.targetLanguage || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.paymentUsed ? 'O' : '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.recommender || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{new Date(member.createdAt).toLocaleString('ko-KR')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{member.deletedAt ? '탈퇴' : '정상'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <div className="bg-white p-4 rounded-lg shadow">
    <h3 className="text-sm font-medium text-gray-500 mb-2">{title}</h3>
    <p className="text-2xl font-bold text-blue-600">{value.toLocaleString()}</p>
  </div>
);

export default HomeDashboard;
