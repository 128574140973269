import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import { ChevronDownIcon } from '@heroicons/react/solid';
import API_BASE_URL from '../../config';
const ErrorEditor = () => {
  const navigate = useNavigate();
  const { errorId } = useParams(); // URL에서 오류 ID를 가져옵니다.

  // 오류 신고 정보 상태 관리
  const [reportData, setReportData] = useState({
    id: '',
    title: '',
    content: '',
    category: '',
    location: '',
    language: '',
    isPinned: false,
    images: [],
    createdAt: '',
    author: {
      id: '',
      username: '',
      nickname: '',
      // 필요한 경우 다른 author 필드 추가
    }
  });

  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);

  const categories = ['mbti', 'persona', 'situation', 'psychology', 'expression', 'other']; // 예시 카테고리 목록
  const languages = ['kor', 'eng', 'jpn']; // 예시 언어 목록
  const statuses = ['반영', '미반영']; // 예시 상태 목록

  const handleCategoryChange = (category) => {
      setReportData(prev => ({ ...prev, category }));
      setIsCategoryDropdownOpen(false);
  };

  const handleLanguageChange = (language) => {
    setReportData(prev => ({ ...prev, language }));
    setIsLanguageDropdownOpen(false);
  };

  const handleStatusChange = (status) => {
    setReportData(prev => ({ ...prev, status }));
    setIsStatusDropdownOpen(false);
  };

  const handleCategoryClick = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    setIsLanguageDropdownOpen(false); // 언어 드롭다운 닫기
    setIsStatusDropdownOpen(false); // 상태 드롭다운 닫기
  };

  const handleLanguageClick = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
    setIsCategoryDropdownOpen(false); // 분류 드롭다운 닫기
    setIsStatusDropdownOpen(false); // 상태 드롭다운 닫기
  };

  const handleStatusClick = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
    setIsCategoryDropdownOpen(false); // 분류 드롭다운 닫기
    setIsLanguageDropdownOpen(false); // 언어 드롭다운 닫기
  };

  useEffect(() => {
    console.log('useEffect 실행, id:', errorId);
    if (errorId) {
      fetchErrorReport(errorId);
    }
  }, [errorId]);

  const fetchErrorReport = async (reportId) => {
    try {
      console.log('API 요청 시작:', reportId);
      const response = await axios.get(`${API_BASE_URL}/errorreports/${reportId}`);
      console.log('API 응답 데이터:', response.data);
      
      // API 응답 데이터를 그대로 상태에 설정
      setReportData(response.data);
    } catch (error) {
      console.error('Error fetching error report:', error);
    }
  };

  const handleSave = async () => {
    try {
      if (errorId) {
        // 기존 오류 수정
        await axios.patch(`/errorreports/${errorId}`, reportData);
      } else {
        // 새 오류 생성
        await axios.post('/errorreports', reportData);
      }
      navigate('/error');
    } catch (error) {
      console.error('Error saving error report:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReportData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      {console.log('렌더링 시 reportData:', reportData)}
      <Navigation />
      {/* 상단 버튼 */}
      <div className="flex justify-between items-center mb-5">
        <h1 
          className="text-2xl font-bold text-left"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          오류신고
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/error')}
            className="w-[100px] h-[48px] bg-[#666B81] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            목록가기
          </button>
          <button 
            onClick={handleSave}
            className="w-[100px] h-[48px] bg-[#3677F6] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            저장하기
          </button>
        </div>
      </div>

      {/* 신고자 정보 및 상태값 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          신고자 정보 및 상태값
        </h2>
        <div className="grid grid-cols-4 gap-8">
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              ID
            </label>
            <div 
              className="bg-[#E2E8EF] flex items-center"
              style={{
                height: '48px',
                padding: '18px 14px',
                borderRadius: '12px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}>
                {reportData.author?.userid || ''}
              </div>
          </div>
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              닉네임
            </label>
            <div 
              className="bg-[#E2E8EF] flex items-center"
              style={{
                height: '48px',
                padding: '18px 14px',
                borderRadius: '12px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}>
                {reportData.author?.nickname || ''}
              </div>
          </div>
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              신고 일시
            </label>
            <div 
              className="bg-[#E2E8EF] flex items-center"
              style={{
                height: '48px',
                padding: '18px 14px',
                borderRadius: '12px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}>
                {reportData.createdAt || ''}
              </div>
          </div>
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              SN
            </label>
            <div 
              className="bg-[#E2E8EF] flex items-center"
              style={{
                height: '48px',
                padding: '18px 14px',
                borderRadius: '12px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {reportData.id || ''}
            </div>
          </div>
        </div>
      </div>

      {/* 오류 위치 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          오류 위치
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              위치
            </label>
            <div className="relative">
              <div
                className="bg-white p-2 rounded-lg flex items-center justify-between cursor-pointer"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                }}
                onClick={handleCategoryClick}
              >
                <span>{reportData.location || ''}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isCategoryDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleCategoryChange(category)}
                    >
                      {category}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              언어
            </label>
            <div className="relative">
              <div
                className="bg-white p-2 rounded-lg flex items-center justify-between cursor-pointer"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                }}
                onClick={handleLanguageClick}
              >
                <span>{reportData.language || ''}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isLanguageDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {languages.map((language, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLanguageChange(language)}
                    >
                      {language}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <label 
              className="block text-sm font-bold mb-2"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                color: '0E111F',
              }}
            >
              상태
            </label>
            <div className="relative">
              <div
                className="bg-white p-2 rounded-lg flex items-center justify-between cursor-pointer"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                }}
                onClick={handleStatusClick}
              >
                <span>{reportData.isPinned ? '반영' : '미반영'}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isStatusDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {statuses.map((status, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setReportData(prev => ({ ...prev, isPinned: status === '반영' }));
                        setIsStatusDropdownOpen(false);
                      }}
                    >
                      {status}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 신고 내용 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">신고 내용</h2>
        <div className="grid grid-cols-1 gap-8">
          <div>
            <label className="block text-sm font-bold mb-2">카테고리</label>
            <input
              type="text"
              name="location"
              value={reportData.category || ''}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              disabled
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">제목</label>
            <input
              type="text"
              name="title"
              value={reportData.title || ''}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              disabled
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">내용</label>
            <textarea
              name="content"
              value={reportData.content || ''}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              rows="4"
              disabled
            />
          </div>
        </div>
      </div>

      {/* 첨부 이미지 */}
      <div className="bg-white rounded-lg p-6 shadow-md">
        <h2 className="text-xl font-bold mb-4">첨부 이미지</h2>
        <div className="grid grid-cols-3 gap-4">
          {reportData.images && reportData.images.map((image, index) => (
            <div
              key={index}
              className="w-full h-32 bg-gray-100 flex items-center justify-center relative"
            >
              <img src={`${API_BASE_URL}/upload${image}`} alt={`첨부 이미지 ${index + 1}`} className="h-full object-cover" onClick={() => window.open(`${API_BASE_URL}/upload${image}`, '_blank')} />
              <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2">
                <span>클릭하여 확대</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ErrorEditor;